import * as Sentry from '@sentry/vue';
import * as Vue from 'vue';

import { Environment, EnvironmentType } from '~/environment';

import { BrowserTracing } from '@sentry/tracing';
import { Router } from 'vue-router';
import { useEnsured as useEnvironmentEnsured } from '~/composables/use-environment';

export enum BreadcrumbCategory {
  Signup = 'signup',
  Analytics = 'analytics'
}

class SentryService {
  private _environment: Environment;

  get isEnabled(): boolean {
    return (
      this._environment.type === EnvironmentType.Dev ||
      this._environment.type === EnvironmentType.Prod
    );
  }

  constructor() {
    this._environment = useEnvironmentEnsured();
  }

  install(app: Vue.App<Element>, router: Router) {
    if (this.isEnabled) {
      if (!this._environment.settings.commitId || !this._environment.settings.sentryUrl) {
        throw new Error('Sentry is enabled but commitId or sentryUrl is not set');
      }

      Sentry.init({
        app,
        release: this._environment.settings.commitId,
        /*
          MODE represents the name of production-like environment the app is built for
          Read more about the "mode" concept in Vite: https://vitejs.dev/guide/env-and-mode.html#modes
        */
        environment: this._environment.type,
        dsn: this._environment.settings.sentryUrl,
        ignoreErrors: [
          // Ignore cancelled fetch requests (e.g. when navigating away from the page)
          'TypeError: Failed to fetch', // Chrome
          'TypeError: NetworkError when attempting to fetch resource.', // Firefox
          'TypeError: cancelled', // Safari
          // Ignore failed asset preload attempts
          'Unable to preload CSS for'
        ],
        integrations: function (integrations) {
          /*
            Integrations array will have all default @sentry/browser integrations: 
            https://docs.sentry.io/platforms/javascript/guides/vue/configuration/integrations/default/#enabled-by-default
          */
          integrations.push(
            new BrowserTracing({
              routingInstrumentation: Sentry.vueRouterInstrumentation(router)
            })
          );

          return integrations;
        },
        tracesSampleRate: 1.0
      });
    }
  }

  warn(msg: string, context: Record<string, unknown> = {}): void {
    if (this.isEnabled) {
      Sentry.withScope(scope => {
        if (Object.keys(context).length) {
          scope.setContext('tracingContext', context);
        }

        Sentry.captureMessage(msg, Sentry.Severity.Warning);
      });
    }
  }

  error(error: unknown | Error, context: Record<string, unknown> = {}): void {
    if (this.isEnabled) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error);

        if (Object.keys(context).length) {
          scope.setContext('tracingContext', context);
        }

        try {
          Sentry.captureException(error);
        } catch {
          scope.setContext('tracingContext', context);
          Sentry.captureMessage(
            'Unknown error and unable to convert error.',
            Sentry.Severity.Error
          );
        }
      });
    }
  }

  setContext(name: string, context: Record<string, unknown> | null = {}): void {
    if (this.isEnabled) {
      Sentry.setContext(name, context);
    }
  }

  addBreadcrumb(category: BreadcrumbCategory, payload: string | Record<string, any>): void {
    if (this.isEnabled) {
      const breadcrumb: Sentry.Breadcrumb = { category };

      if (typeof payload === 'string') {
        breadcrumb['message'] = payload;
      } else {
        breadcrumb['data'] = payload;
      }

      Sentry.addBreadcrumb(breadcrumb);
    }
  }
}

export default new SentryService();

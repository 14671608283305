/**
 * Imports are moved to the top of processed files, so if we want to initialize the environment before other imports we
 * need to place the initialization in a module we can import.
 */
import { Environment, EnvironmentType } from './environment';
import { initialize as initializeEnvironment } from './composables/use-environment';

initializeEnvironment({
  type: Environment.environmentTypeFromMode(import.meta.env.MODE) || EnvironmentType.Test,
  commitId: import.meta.env.CI_COMMIT_ID as string | undefined,
  sentryUrl: import.meta.env.VITE_SENTRY_DSN as string | undefined,
  helpScoutBeaconId: import.meta.env.VITE_HELP_SCOUT_BEACON_ID as string | undefined,
  isVSUEnabled: import.meta.env.VITE_IS_VSU_ENABLED === 'true',
  mpApiBaseUrl: <string>import.meta.env.VITE_MP_API_BASE_URL
});

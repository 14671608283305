import { InjectionKey } from 'vue';

import { Common as CommonApi } from '../apis/common';

/**
 * Common models shared between different parts of the site.
 */

/**
 * Simple information about a user
 */
export interface User {
  // Id of the user
  id: string;
  // User's first name
  firstName: string;
  // User's last name
  lastName: string;
  // Auth'd url for the user's thumbnail
  thumbnailUrl: string | undefined;
}

/**
 * URLs to install the app, with baked in deep link to the Sharecast
 */
export interface AppInstallLinks {
  // AppStore link
  ios: string;
  // PlayStore link
  android: string;
}

/**
 * Shared
 */
export enum MessageType {
  Video = 'video',
  Note = 'note',
  Photo = 'photo'
}

interface MessageBase {
  id: string;
  // Whether the data in this message is limited because it's been accessed too many times
  isLimited: boolean;
  // App install links, linking to the message
  installLinks: AppInstallLinks;
  // Date that the message was created
  createdAt: Date;
}

/**
 * Information about a video message
 */
export interface Message extends MessageBase {
  // Auth'd url for the thumbnail of the message
  thumbnailUrl: string;
  // Id of the video
  videoId: string;
  // Url of the video
  videoUrl: string | undefined;
  // Url of the photo (photo message only)
  imageUrl: string | undefined;
  // Auth token needed to access the video
  videoAuthToken: string | undefined;
  // Read token needed to access the video
  videoReadToken: string | undefined;
}

/**
 * Information about a note message
 */
export interface Note extends MessageBase {
  // Whether text is animated or not
  animateText: boolean;
  // content of message
  text: string;
  // color of the background
  textBackground: number;
}

export interface BrandColors {
  JOYA_BLUE: string;
  JOYA_PURPLE: string;
  JOYA_RED: string;
  JOYA_ORANGE: string;
  JOYA_YELLOW: string;
  JOYA_GREEN: string;
}

export const animatableEmojis: { [index: string]: string } = {
  '😆': 'Laughing',
  '😂': 'Laughing%20Tears',
  '😊': 'Blushing',
  '😇': 'Angel',
  '🙂': 'Happy',
  '😍': 'Heart%20Eyes',
  '😘': 'Kiss',
  '🤪': 'Silly',
  '😎': 'Cool',
  '☹️': 'Sad',
  '😢': 'Sad%20Tear',
  '😭': 'Crying',
  '😤': 'Nose%20Steam',
  '🤬': 'Cussing',
  '😳': 'Flushed',
  '😮': 'Shocked',
  '😴': 'Sleeping',
  '🤮': 'Puking',
  '🙌': 'Praise%20-%20A',
  '🙌🏻': 'Praise%20-%20B',
  '🙌🏼': 'Praise%20-%20C',
  '🙌🏽': 'Praise%20-%20D',
  '🙌🏾': 'Praise%20-%20E',
  '🙌🏿': 'Praise%20-%20F',
  '👏': 'Clapping%20-%20A',
  '👏🏻': 'Clapping%20-%20B',
  '👏🏼': 'Clapping%20-%20C',
  '👏🏽': 'Clapping%20-%20D',
  '👏🏾': 'Clapping%20-%20E',
  '👏🏿': 'Clapping%20-%20F',
  '👍': 'Thumbs%20Up%20-%20A',
  '👍🏻': 'Thumbs%20Up%20-%20B',
  '👍🏼': 'Thumbs%20Up%20-%20C',
  '👍🏽': 'Thumbs%20Up%20-%20D',
  '👍🏾': 'Thumbs%20Up%20-%20E',
  '👍🏿': 'Thumbs%20Up%20-%20F',
  '👎': 'Thumbs%20Down%20-%20A',
  '👎🏻': 'Thumbs%20Down%20-%20B',
  '👎🏼': 'Thumbs%20Down%20-%20C',
  '👎🏽': 'Thumbs%20Down%20-%20D',
  '👎🏾': 'Thumbs%20Down%20-%20E',
  '👎🏿': 'Thumbs%20Down%20-%20F',
  '👊': 'Fist%20-%20A',
  '👊🏻': 'Fist%20-%20B',
  '👊🏼': 'Fist%20-%20C',
  '👊🏽': 'Fist%20-%20D',
  '👊🏾': 'Fist%20-%20E',
  '👊🏿': 'Fist%20-%20F',
  '✌️': 'Peace%20-%20A',
  '✌🏻': 'Peace%20-%20B',
  '✌🏼': 'Peace%20-%20C',
  '✌🏽': 'Peace%20-%20D',
  '✌🏾': 'Peace%20-%20E',
  '✌🏿': 'Peace%20-%20F',
  '🤘': 'Rock%20On%20-%20A',
  '🤘🏻': 'Rock%20On%20-%20B',
  '🤘🏼': 'Rock%20On%20-%20C',
  '🤘🏽': 'Rock%20On%20-%20D',
  '🤘🏾': 'Rock%20On%20-%20E',
  '🤘🏿': 'Rock%20On%20-%20F',
  '👋': 'Waving%20-%20A',
  '👋🏻': 'Waving%20-%20B',
  '👋🏼': 'Waving%20-%20C',
  '👋🏽': 'Waving%20-%20D',
  '👋🏾': 'Waving%20-%20E',
  '👋🏿': 'Waving%20-%20F',
  '🤙': 'Shaka%20-%20A',
  '🤙🏻': 'Shaka%20-%20B',
  '🤙🏼': 'Shaka%20-%20C',
  '🤙🏽': 'Shaka%20-%20D',
  '🤙🏾': 'Shaka%20-%20E',
  '🤙🏿': 'Shaka%20-%20F',
  '🙏': 'Prayer%20-%20A',
  '🙏🏻': 'Prayer%20-%20B',
  '🙏🏼': 'Prayer%20-%20C',
  '🙏🏽': 'Prayer%20-%20D',
  '🙏🏾': 'Prayer%20-%20E',
  '🙏🏿': 'Prayer%20-%20F',
  '❤️': 'Beating%20Heart'
};

export const CommonApiInjectionKey: InjectionKey<CommonApi> = Symbol('CommonApi');
export const BrandColorsInjectionKey: InjectionKey<BrandColors> = Symbol('BrandColors');

/**
 * Combine what's available of a user's first and last name.
 */
export function userFirstAndLast(user: User) {
  return [user.firstName, user.lastName].filter(Boolean).join(' ');
}

/**
 * Types for adding custom error messages on not found page
 */
export enum NotFoundWithCustomMessage {
  Message = 'message',
  Invitation = 'invitation',
  ProMessage = 'pro-message'
}

import { Environment, EnvironmentSettings } from '~/environment';

let currentEnvironment: Environment | undefined;

export function initialize(settings: EnvironmentSettings): Environment {
  if (currentEnvironment) {
    throw new Error('Environment has already been initialized');
  }
  currentEnvironment = new Environment(settings);
  return currentEnvironment;
}

export function useEnsured(): Environment {
  if (!currentEnvironment) {
    throw new Error('Environment not initialized before use');
  }

  return currentEnvironment;
}

/* tslint:disable */
/* eslint-disable */
/**
 * Marco Polo Web API
 * An API for Marco Polo web projects
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bvanwinkle@happybits.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';
// Import our custom createRequestFunction from outside the generated directory, so we can maintain the function
// normally, instead of as a template.
import { createRequestFunction, RequestFailureSeverity, MPAxiosRequestConfig } from '../generated-helpers';

/**
 * 
 * @export
 * @interface AppInstallLinks
 */
export interface AppInstallLinks {
    /**
     * 
     * @type {string}
     * @memberof AppInstallLinks
     */
    'ios': string;
    /**
     * 
     * @type {string}
     * @memberof AppInstallLinks
     */
    'android': string;
}
/**
 * 
 * @export
 * @interface AuthBody
 */
export interface AuthBody {
    /**
     * 
     * @type {string}
     * @memberof AuthBody
     */
    'secret'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthBody
     */
    'app_version'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthBody
     */
    'user_agent'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthBody
     */
    'app_build'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthBody
     */
    'locale'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthBody
     */
    'timezone'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthBody
     */
    'share_token'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthBody
     */
    'invite_token'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthBody
     */
    'message_token'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthBody
     */
    'seconds_share_token'?: string;
}
/**
 * 
 * @export
 * @interface AuthResponse
 */
export interface AuthResponse {
    /**
     * 
     * @type {string}
     * @memberof AuthResponse
     */
    'device_id': string;
    /**
     * 
     * @type {string}
     * @memberof AuthResponse
     */
    'user_id'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AuthResponse
     */
    'expiration_time': number;
    /**
     * 
     * @type {boolean}
     * @memberof AuthResponse
     */
    'is_mp_app_user'?: boolean;
}
/**
 * 
 * @export
 * @interface AuthResponseBody
 */
export interface AuthResponseBody {
    /**
     * 
     * @type {string}
     * @memberof AuthResponseBody
     */
    'device_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthResponseBody
     */
    'user_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof AuthResponseBody
     */
    'expiration_time'?: number;
}
/**
 * Event fired when a user starts viewing a Sharecast intro video on Sharecast for Web.  This intentionally overlaps with the NEW PLAY event and provides more Sharecast related details
 * @export
 * @interface BroadcastIntroPlay
 */
export interface BroadcastIntroPlay {
    /**
     * 
     * @type {boolean}
     * @memberof BroadcastIntroPlay
     */
    'validate': boolean;
    /**
     * 
     * @type {string}
     * @memberof BroadcastIntroPlay
     */
    'device_id': string;
    /**
     * 
     * @type {number}
     * @memberof BroadcastIntroPlay
     */
    'event_time': number;
    /**
     * 
     * @type {string}
     * @memberof BroadcastIntroPlay
     */
    'user_id'?: string;
    /**
     * 
     * @type {BroadcastInviteProperties & MessageProperties}
     * @memberof BroadcastIntroPlay
     */
    'properties': BroadcastInviteProperties & MessageProperties;
    /**
     * 
     * @type {Array<string>}
     * @memberof BroadcastIntroPlay
     */
    'projects': Array<BroadcastIntroPlayProjectsEnum>;
    /**
     * 
     * @type {string}
     * @memberof BroadcastIntroPlay
     */
    'event_name': BroadcastIntroPlayEventNameEnum;
}

/**
    * @export
    * Constant type enum
    */
export type BroadcastIntroPlayProjectsEnum = 'Activation';
/**
    * @export
    * Constant type enum
    */
export type BroadcastIntroPlayEventNameEnum = 'BCAST INTRO PLAY';

/**
 * 
 * @export
 * @interface BroadcastIntroPlayAllOf
 */
export interface BroadcastIntroPlayAllOf {
    /**
     * 
     * @type {Array<string>}
     * @memberof BroadcastIntroPlayAllOf
     */
    'projects'?: Array<BroadcastIntroPlayAllOfProjectsEnum>;
    /**
     * 
     * @type {string}
     * @memberof BroadcastIntroPlayAllOf
     */
    'event_name': BroadcastIntroPlayAllOfEventNameEnum;
    /**
     * 
     * @type {BroadcastInviteProperties & MessageProperties}
     * @memberof BroadcastIntroPlayAllOf
     */
    'properties': BroadcastInviteProperties & MessageProperties;
}

/**
    * @export
    * Constant type enum
    */
export type BroadcastIntroPlayAllOfProjectsEnum = 'Activation';
/**
    * @export
    * Constant type enum
    */
export type BroadcastIntroPlayAllOfEventNameEnum = 'BCAST INTRO PLAY';

/**
 * Event fired when a user clicks the Android or iOS install button on Sharecast for Web invite page.  The user may or may not complete install after clicking the button.
 * @export
 * @interface BroadcastInviteInstall
 */
export interface BroadcastInviteInstall {
    /**
     * 
     * @type {boolean}
     * @memberof BroadcastInviteInstall
     */
    'validate': boolean;
    /**
     * 
     * @type {string}
     * @memberof BroadcastInviteInstall
     */
    'device_id': string;
    /**
     * 
     * @type {number}
     * @memberof BroadcastInviteInstall
     */
    'event_time': number;
    /**
     * 
     * @type {string}
     * @memberof BroadcastInviteInstall
     */
    'user_id'?: string;
    /**
     * 
     * @type {BroadcastInviteProperties & InstallProperties}
     * @memberof BroadcastInviteInstall
     */
    'properties': BroadcastInviteProperties & InstallProperties;
    /**
     * 
     * @type {Array<string>}
     * @memberof BroadcastInviteInstall
     */
    'projects': Array<BroadcastInviteInstallProjectsEnum>;
    /**
     * 
     * @type {string}
     * @memberof BroadcastInviteInstall
     */
    'event_name': BroadcastInviteInstallEventNameEnum;
}

/**
    * @export
    * Constant type enum
    */
export type BroadcastInviteInstallProjectsEnum = 'Activation';
/**
    * @export
    * Constant type enum
    */
export type BroadcastInviteInstallEventNameEnum = 'BCAST INVITE INSTALL';

/**
 * 
 * @export
 * @interface BroadcastInviteInstallAllOf
 */
export interface BroadcastInviteInstallAllOf {
    /**
     * 
     * @type {Array<string>}
     * @memberof BroadcastInviteInstallAllOf
     */
    'projects'?: Array<BroadcastInviteInstallAllOfProjectsEnum>;
    /**
     * 
     * @type {string}
     * @memberof BroadcastInviteInstallAllOf
     */
    'event_name': BroadcastInviteInstallAllOfEventNameEnum;
    /**
     * 
     * @type {BroadcastInviteProperties & InstallProperties}
     * @memberof BroadcastInviteInstallAllOf
     */
    'properties': BroadcastInviteProperties & InstallProperties;
}

/**
    * @export
    * Constant type enum
    */
export type BroadcastInviteInstallAllOfProjectsEnum = 'Activation';
/**
    * @export
    * Constant type enum
    */
export type BroadcastInviteInstallAllOfEventNameEnum = 'BCAST INVITE INSTALL';

/**
 * 
 * @export
 * @interface BroadcastInviteProperties
 */
export interface BroadcastInviteProperties {
    /**
     * 
     * @type {string}
     * @memberof BroadcastInviteProperties
     */
    'ConversationID': string;
    /**
     * 
     * @type {string}
     * @memberof BroadcastInviteProperties
     */
    'CurrentUserRole': BroadcastInvitePropertiesCurrentUserRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof BroadcastInviteProperties
     */
    'InviteID': string;
    /**
     * 
     * @type {boolean}
     * @memberof BroadcastInviteProperties
     */
    'IsSharecastLink': boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum BroadcastInvitePropertiesCurrentUserRoleEnum {
    Owner = 'owner',
    Coowner = 'coowner',
    Viewer = 'viewer',
    Invitee = 'invitee'
}

/**
 * 
 * @export
 * @interface BroadcastInvitePropertiesAllOf
 */
export interface BroadcastInvitePropertiesAllOf {
    /**
     * 
     * @type {string}
     * @memberof BroadcastInvitePropertiesAllOf
     */
    'InviteID': string;
    /**
     * 
     * @type {boolean}
     * @memberof BroadcastInvitePropertiesAllOf
     */
    'IsSharecastLink': boolean;
}
/**
 * Event fired when a user views the page for a Sharecast invite.  The invite could be a sharecast link, or an individual invite link.
 * @export
 * @interface BroadcastInviteView
 */
export interface BroadcastInviteView {
    /**
     * 
     * @type {boolean}
     * @memberof BroadcastInviteView
     */
    'validate': boolean;
    /**
     * 
     * @type {string}
     * @memberof BroadcastInviteView
     */
    'device_id': string;
    /**
     * 
     * @type {number}
     * @memberof BroadcastInviteView
     */
    'event_time': number;
    /**
     * 
     * @type {string}
     * @memberof BroadcastInviteView
     */
    'user_id'?: string;
    /**
     * 
     * @type {BroadcastInviteProperties}
     * @memberof BroadcastInviteView
     */
    'properties': BroadcastInviteProperties;
    /**
     * 
     * @type {Array<string>}
     * @memberof BroadcastInviteView
     */
    'projects': Array<BroadcastInviteViewProjectsEnum>;
    /**
     * 
     * @type {string}
     * @memberof BroadcastInviteView
     */
    'event_name': BroadcastInviteViewEventNameEnum;
}

/**
    * @export
    * Constant type enum
    */
export type BroadcastInviteViewProjectsEnum = 'Activation';
/**
    * @export
    * Constant type enum
    */
export type BroadcastInviteViewEventNameEnum = 'BCAST INVITE VIEW';

/**
 * 
 * @export
 * @interface BroadcastInviteViewAllOf
 */
export interface BroadcastInviteViewAllOf {
    /**
     * 
     * @type {Array<string>}
     * @memberof BroadcastInviteViewAllOf
     */
    'projects'?: Array<BroadcastInviteViewAllOfProjectsEnum>;
    /**
     * 
     * @type {string}
     * @memberof BroadcastInviteViewAllOf
     */
    'event_name': BroadcastInviteViewAllOfEventNameEnum;
    /**
     * 
     * @type {BroadcastInviteProperties}
     * @memberof BroadcastInviteViewAllOf
     */
    'properties': BroadcastInviteProperties;
}

/**
    * @export
    * Constant type enum
    */
export type BroadcastInviteViewAllOfProjectsEnum = 'Activation';
/**
    * @export
    * Constant type enum
    */
export type BroadcastInviteViewAllOfEventNameEnum = 'BCAST INVITE VIEW';

/**
 * 
 * @export
 * @interface BroadcastLinksResponse
 */
export interface BroadcastLinksResponse {
    /**
     * 
     * @type {string}
     * @memberof BroadcastLinksResponse
     */
    'conversation_id': string;
    /**
     * 
     * @type {string}
     * @memberof BroadcastLinksResponse
     */
    'web_invite_id': string;
    /**
     * 
     * @type {User}
     * @memberof BroadcastLinksResponse
     */
    'creator_info': User;
    /**
     * 
     * @type {string}
     * @memberof BroadcastLinksResponse
     */
    'invited_phone'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BroadcastLinksResponse
     */
    'invitee_subscribed': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BroadcastLinksResponse
     */
    'over_limit_viewers': boolean;
    /**
     * 
     * @type {AppInstallLinks}
     * @memberof BroadcastLinksResponse
     */
    'install_links': AppInstallLinks;
    /**
     * 
     * @type {string}
     * @memberof BroadcastLinksResponse
     */
    'thumbnail_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof BroadcastLinksResponse
     */
    'title': string;
    /**
     * 
     * @type {VideoMessage}
     * @memberof BroadcastLinksResponse
     */
    'intro_message': VideoMessage;
}
/**
 * 
 * @export
 * @interface BroadcastMessageBody
 */
export interface BroadcastMessageBody {
    /**
     * 
     * @type {string}
     * @memberof BroadcastMessageBody
     */
    'web_user_id': string;
}
/**
 * Event fired when a user clicks the Android or iOS install button on Sharecast for Web message page  The user may or may not complete install after clicking the button.
 * @export
 * @interface BroadcastMessageInstall
 */
export interface BroadcastMessageInstall {
    /**
     * 
     * @type {boolean}
     * @memberof BroadcastMessageInstall
     */
    'validate': boolean;
    /**
     * 
     * @type {string}
     * @memberof BroadcastMessageInstall
     */
    'device_id': string;
    /**
     * 
     * @type {number}
     * @memberof BroadcastMessageInstall
     */
    'event_time': number;
    /**
     * 
     * @type {string}
     * @memberof BroadcastMessageInstall
     */
    'user_id'?: string;
    /**
     * 
     * @type {BroadcastMessageProperties & InstallProperties}
     * @memberof BroadcastMessageInstall
     */
    'properties': BroadcastMessageProperties & InstallProperties;
    /**
     * 
     * @type {Array<string>}
     * @memberof BroadcastMessageInstall
     */
    'projects': Array<BroadcastMessageInstallProjectsEnum>;
    /**
     * 
     * @type {string}
     * @memberof BroadcastMessageInstall
     */
    'event_name': BroadcastMessageInstallEventNameEnum;
}

/**
    * @export
    * Constant type enum
    */
export type BroadcastMessageInstallProjectsEnum = 'Activation';
/**
    * @export
    * Constant type enum
    */
export type BroadcastMessageInstallEventNameEnum = 'BCAST MESSAGE INSTALL';

/**
 * 
 * @export
 * @interface BroadcastMessageInstallAllOf
 */
export interface BroadcastMessageInstallAllOf {
    /**
     * 
     * @type {Array<string>}
     * @memberof BroadcastMessageInstallAllOf
     */
    'projects'?: Array<BroadcastMessageInstallAllOfProjectsEnum>;
    /**
     * 
     * @type {string}
     * @memberof BroadcastMessageInstallAllOf
     */
    'event_name': BroadcastMessageInstallAllOfEventNameEnum;
    /**
     * 
     * @type {BroadcastMessageProperties & InstallProperties}
     * @memberof BroadcastMessageInstallAllOf
     */
    'properties': BroadcastMessageProperties & InstallProperties;
}

/**
    * @export
    * Constant type enum
    */
export type BroadcastMessageInstallAllOfProjectsEnum = 'Activation';
/**
    * @export
    * Constant type enum
    */
export type BroadcastMessageInstallAllOfEventNameEnum = 'BCAST MESSAGE INSTALL';

/**
 * Event fired when a user starts viewing a Sharecast message video on Sharecast for Web.  This intentionally overlaps with the NEW PLAY event and provides more Sharecast related details
 * @export
 * @interface BroadcastMessagePlay
 */
export interface BroadcastMessagePlay {
    /**
     * 
     * @type {boolean}
     * @memberof BroadcastMessagePlay
     */
    'validate': boolean;
    /**
     * 
     * @type {string}
     * @memberof BroadcastMessagePlay
     */
    'device_id': string;
    /**
     * 
     * @type {number}
     * @memberof BroadcastMessagePlay
     */
    'event_time': number;
    /**
     * 
     * @type {string}
     * @memberof BroadcastMessagePlay
     */
    'user_id'?: string;
    /**
     * 
     * @type {BroadcastMessageProperties}
     * @memberof BroadcastMessagePlay
     */
    'properties': BroadcastMessageProperties;
    /**
     * 
     * @type {Array<string>}
     * @memberof BroadcastMessagePlay
     */
    'projects': Array<BroadcastMessagePlayProjectsEnum>;
    /**
     * 
     * @type {string}
     * @memberof BroadcastMessagePlay
     */
    'event_name': BroadcastMessagePlayEventNameEnum;
}

/**
    * @export
    * Constant type enum
    */
export type BroadcastMessagePlayProjectsEnum = 'Activation';
/**
    * @export
    * Constant type enum
    */
export type BroadcastMessagePlayEventNameEnum = 'BCAST MESSAGE PLAY';

/**
 * 
 * @export
 * @interface BroadcastMessagePlayAllOf
 */
export interface BroadcastMessagePlayAllOf {
    /**
     * 
     * @type {Array<string>}
     * @memberof BroadcastMessagePlayAllOf
     */
    'projects'?: Array<BroadcastMessagePlayAllOfProjectsEnum>;
    /**
     * 
     * @type {string}
     * @memberof BroadcastMessagePlayAllOf
     */
    'event_name': BroadcastMessagePlayAllOfEventNameEnum;
    /**
     * 
     * @type {BroadcastMessageProperties}
     * @memberof BroadcastMessagePlayAllOf
     */
    'properties': BroadcastMessageProperties;
}

/**
    * @export
    * Constant type enum
    */
export type BroadcastMessagePlayAllOfProjectsEnum = 'Activation';
/**
    * @export
    * Constant type enum
    */
export type BroadcastMessagePlayAllOfEventNameEnum = 'BCAST MESSAGE PLAY';

/**
 * 
 * @export
 * @interface BroadcastMessageProperties
 */
export interface BroadcastMessageProperties {
    /**
     * 
     * @type {string}
     * @memberof BroadcastMessageProperties
     */
    'ConversationID': string;
    /**
     * 
     * @type {string}
     * @memberof BroadcastMessageProperties
     */
    'CurrentUserRole': BroadcastMessagePropertiesCurrentUserRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof BroadcastMessageProperties
     */
    'MessageID': string;
}

/**
    * @export
    * @enum {string}
    */
export enum BroadcastMessagePropertiesCurrentUserRoleEnum {
    Owner = 'owner',
    Coowner = 'coowner',
    Viewer = 'viewer',
    Invitee = 'invitee'
}

/**
 * 
 * @export
 * @interface BroadcastMessageResponse
 */
export interface BroadcastMessageResponse {
    /**
     * 
     * @type {string}
     * @memberof BroadcastMessageResponse
     */
    'conversation_id': string;
    /**
     * 
     * @type {User}
     * @memberof BroadcastMessageResponse
     */
    'creator_info': User;
    /**
     * 
     * @type {boolean}
     * @memberof BroadcastMessageResponse
     */
    'over_limit_viewers': boolean;
    /**
     * 
     * @type {AppInstallLinks}
     * @memberof BroadcastMessageResponse
     */
    'install_links': AppInstallLinks;
    /**
     * 
     * @type {string}
     * @memberof BroadcastMessageResponse
     */
    'thumbnail_url': string | null;
    /**
     * 
     * @type {string}
     * @memberof BroadcastMessageResponse
     */
    'title': string;
    /**
     * 
     * @type {VideoMessage | NoteMessage}
     * @memberof BroadcastMessageResponse
     */
    'message': VideoMessage | NoteMessage;
}
/**
 * Event fired when a user views the page for a Sharecast message.
 * @export
 * @interface BroadcastMessageView
 */
export interface BroadcastMessageView {
    /**
     * 
     * @type {boolean}
     * @memberof BroadcastMessageView
     */
    'validate': boolean;
    /**
     * 
     * @type {string}
     * @memberof BroadcastMessageView
     */
    'device_id': string;
    /**
     * 
     * @type {number}
     * @memberof BroadcastMessageView
     */
    'event_time': number;
    /**
     * 
     * @type {string}
     * @memberof BroadcastMessageView
     */
    'user_id'?: string;
    /**
     * 
     * @type {BroadcastMessageProperties}
     * @memberof BroadcastMessageView
     */
    'properties': BroadcastMessageProperties;
    /**
     * 
     * @type {Array<string>}
     * @memberof BroadcastMessageView
     */
    'projects': Array<BroadcastMessageViewProjectsEnum>;
    /**
     * 
     * @type {string}
     * @memberof BroadcastMessageView
     */
    'event_name': BroadcastMessageViewEventNameEnum;
}

/**
    * @export
    * Constant type enum
    */
export type BroadcastMessageViewProjectsEnum = 'Activation';
/**
    * @export
    * Constant type enum
    */
export type BroadcastMessageViewEventNameEnum = 'BCAST MESSAGE VIEW';

/**
 * 
 * @export
 * @interface BroadcastMessageViewAllOf
 */
export interface BroadcastMessageViewAllOf {
    /**
     * 
     * @type {Array<string>}
     * @memberof BroadcastMessageViewAllOf
     */
    'projects'?: Array<BroadcastMessageViewAllOfProjectsEnum>;
    /**
     * 
     * @type {string}
     * @memberof BroadcastMessageViewAllOf
     */
    'event_name': BroadcastMessageViewAllOfEventNameEnum;
    /**
     * 
     * @type {BroadcastMessageProperties}
     * @memberof BroadcastMessageViewAllOf
     */
    'properties': BroadcastMessageProperties;
}

/**
    * @export
    * Constant type enum
    */
export type BroadcastMessageViewAllOfProjectsEnum = 'Activation';
/**
    * @export
    * Constant type enum
    */
export type BroadcastMessageViewAllOfEventNameEnum = 'BCAST MESSAGE VIEW';

/**
 * 
 * @export
 * @interface BroadcastProperties
 */
export interface BroadcastProperties {
    /**
     * 
     * @type {string}
     * @memberof BroadcastProperties
     */
    'ConversationID': string;
    /**
     * 
     * @type {string}
     * @memberof BroadcastProperties
     */
    'CurrentUserRole': BroadcastPropertiesCurrentUserRoleEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum BroadcastPropertiesCurrentUserRoleEnum {
    Owner = 'owner',
    Coowner = 'coowner',
    Viewer = 'viewer',
    Invitee = 'invitee'
}

/**
 * 
 * @export
 * @interface BroadcastPropertiesAllOf
 */
export interface BroadcastPropertiesAllOf {
    /**
     * 
     * @type {string}
     * @memberof BroadcastPropertiesAllOf
     */
    'CurrentUserRole': BroadcastPropertiesAllOfCurrentUserRoleEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum BroadcastPropertiesAllOfCurrentUserRoleEnum {
    Owner = 'owner',
    Coowner = 'coowner',
    Viewer = 'viewer',
    Invitee = 'invitee'
}

/**
 * Events fired as a user progresses through the Sharecast for Web flow to signup for invites. START - user started the flow, NAMES - user provided their name, EMAIL - user provided their email, PHONE - user provided their phone, DONE - user completed the flow and is signed up
 * @export
 * @interface BroadcastUpdatesEvent
 */
export interface BroadcastUpdatesEvent {
    /**
     * 
     * @type {boolean}
     * @memberof BroadcastUpdatesEvent
     */
    'validate': boolean;
    /**
     * 
     * @type {string}
     * @memberof BroadcastUpdatesEvent
     */
    'device_id': string;
    /**
     * 
     * @type {number}
     * @memberof BroadcastUpdatesEvent
     */
    'event_time': number;
    /**
     * 
     * @type {string}
     * @memberof BroadcastUpdatesEvent
     */
    'user_id'?: string;
    /**
     * 
     * @type {BroadcastUpdatesProperties}
     * @memberof BroadcastUpdatesEvent
     */
    'properties': BroadcastUpdatesProperties;
    /**
     * 
     * @type {Array<string>}
     * @memberof BroadcastUpdatesEvent
     */
    'projects': Array<BroadcastUpdatesEventProjectsEnum>;
    /**
     * 
     * @type {string}
     * @memberof BroadcastUpdatesEvent
     */
    'event_name': BroadcastUpdatesEventEventNameEnum;
}

/**
    * @export
    * Constant type enum
    */
export type BroadcastUpdatesEventProjectsEnum = 'Activation';
/**
    * @export
    * @enum {string}
    */
export enum BroadcastUpdatesEventEventNameEnum {
    UpdatesStart = 'BCAST UPDATES START',
    UpdatesNames = 'BCAST UPDATES NAMES',
    UpdatesEmail = 'BCAST UPDATES EMAIL',
    UpdatesPhone = 'BCAST UPDATES PHONE',
    WebJoin = 'BCAST WEB JOIN'
}

/**
 * 
 * @export
 * @interface BroadcastUpdatesEventAllOf
 */
export interface BroadcastUpdatesEventAllOf {
    /**
     * 
     * @type {Array<string>}
     * @memberof BroadcastUpdatesEventAllOf
     */
    'projects'?: Array<BroadcastUpdatesEventAllOfProjectsEnum>;
    /**
     * 
     * @type {string}
     * @memberof BroadcastUpdatesEventAllOf
     */
    'event_name': BroadcastUpdatesEventAllOfEventNameEnum;
    /**
     * 
     * @type {BroadcastUpdatesProperties}
     * @memberof BroadcastUpdatesEventAllOf
     */
    'properties': BroadcastUpdatesProperties;
}

/**
    * @export
    * Constant type enum
    */
export type BroadcastUpdatesEventAllOfProjectsEnum = 'Activation';
/**
    * @export
    * @enum {string}
    */
export enum BroadcastUpdatesEventAllOfEventNameEnum {
    UpdatesStart = 'BCAST UPDATES START',
    UpdatesNames = 'BCAST UPDATES NAMES',
    UpdatesEmail = 'BCAST UPDATES EMAIL',
    UpdatesPhone = 'BCAST UPDATES PHONE',
    WebJoin = 'BCAST WEB JOIN'
}

/**
 * 
 * @export
 * @interface BroadcastUpdatesProperties
 */
export interface BroadcastUpdatesProperties {
    /**
     * 
     * @type {string}
     * @memberof BroadcastUpdatesProperties
     */
    'ConversationID': string;
    /**
     * 
     * @type {string}
     * @memberof BroadcastUpdatesProperties
     */
    'CurrentUserRole': BroadcastUpdatesPropertiesCurrentUserRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof BroadcastUpdatesProperties
     */
    'InviteID': string;
    /**
     * 
     * @type {boolean}
     * @memberof BroadcastUpdatesProperties
     */
    'IsSharecastLink': boolean;
    /**
     * 
     * @type {string}
     * @memberof BroadcastUpdatesProperties
     */
    'UpdatesType': BroadcastUpdatesPropertiesUpdatesTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum BroadcastUpdatesPropertiesCurrentUserRoleEnum {
    Owner = 'owner',
    Coowner = 'coowner',
    Viewer = 'viewer',
    Invitee = 'invitee'
}
/**
    * @export
    * @enum {string}
    */
export enum BroadcastUpdatesPropertiesUpdatesTypeEnum {
    Sms = 'sms',
    Email = 'email'
}

/**
 * 
 * @export
 * @interface BroadcastUpdatesPropertiesAllOf
 */
export interface BroadcastUpdatesPropertiesAllOf {
    /**
     * 
     * @type {string}
     * @memberof BroadcastUpdatesPropertiesAllOf
     */
    'UpdatesType': BroadcastUpdatesPropertiesAllOfUpdatesTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum BroadcastUpdatesPropertiesAllOfUpdatesTypeEnum {
    Sms = 'sms',
    Email = 'email'
}

/**
 * Event fired when there\'s an error from the Sharecast website
 * @export
 * @interface BroadcastWebErrorEvent
 */
export interface BroadcastWebErrorEvent {
    /**
     * 
     * @type {boolean}
     * @memberof BroadcastWebErrorEvent
     */
    'validate': boolean;
    /**
     * 
     * @type {string}
     * @memberof BroadcastWebErrorEvent
     */
    'device_id': string;
    /**
     * 
     * @type {number}
     * @memberof BroadcastWebErrorEvent
     */
    'event_time': number;
    /**
     * 
     * @type {string}
     * @memberof BroadcastWebErrorEvent
     */
    'user_id'?: string;
    /**
     * 
     * @type {BroadcastWebErrorProperties}
     * @memberof BroadcastWebErrorEvent
     */
    'properties': BroadcastWebErrorProperties;
    /**
     * 
     * @type {Array<string>}
     * @memberof BroadcastWebErrorEvent
     */
    'projects': Array<BroadcastWebErrorEventProjectsEnum>;
    /**
     * 
     * @type {string}
     * @memberof BroadcastWebErrorEvent
     */
    'event_name': BroadcastWebErrorEventEventNameEnum;
}

/**
    * @export
    * Constant type enum
    */
export type BroadcastWebErrorEventProjectsEnum = 'Activation';
/**
    * @export
    * Constant type enum
    */
export type BroadcastWebErrorEventEventNameEnum = 'BCAST WEB ERROR';

/**
 * 
 * @export
 * @interface BroadcastWebErrorEventAllOf
 */
export interface BroadcastWebErrorEventAllOf {
    /**
     * 
     * @type {Array<string>}
     * @memberof BroadcastWebErrorEventAllOf
     */
    'projects'?: Array<BroadcastWebErrorEventAllOfProjectsEnum>;
    /**
     * 
     * @type {string}
     * @memberof BroadcastWebErrorEventAllOf
     */
    'event_name': BroadcastWebErrorEventAllOfEventNameEnum;
    /**
     * 
     * @type {BroadcastWebErrorProperties}
     * @memberof BroadcastWebErrorEventAllOf
     */
    'properties': BroadcastWebErrorProperties;
}

/**
    * @export
    * Constant type enum
    */
export type BroadcastWebErrorEventAllOfProjectsEnum = 'Activation';
/**
    * @export
    * Constant type enum
    */
export type BroadcastWebErrorEventAllOfEventNameEnum = 'BCAST WEB ERROR';

/**
 * 
 * @export
 * @interface BroadcastWebErrorProperties
 */
export interface BroadcastWebErrorProperties {
    /**
     * 
     * @type {string}
     * @memberof BroadcastWebErrorProperties
     */
    'ErrorSeverity': BroadcastWebErrorPropertiesErrorSeverityEnum;
    /**
     * 
     * @type {string}
     * @memberof BroadcastWebErrorProperties
     */
    'Message': string;
    /**
     * 
     * @type {string}
     * @memberof BroadcastWebErrorProperties
     */
    'Detail'?: string;
    /**
     * 
     * @type {string}
     * @memberof BroadcastWebErrorProperties
     */
    'ConversationID': string;
    /**
     * 
     * @type {string}
     * @memberof BroadcastWebErrorProperties
     */
    'CurrentUserRole': BroadcastWebErrorPropertiesCurrentUserRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof BroadcastWebErrorProperties
     */
    'InviteID': string;
    /**
     * 
     * @type {boolean}
     * @memberof BroadcastWebErrorProperties
     */
    'IsSharecastLink': boolean;
    /**
     * 
     * @type {string}
     * @memberof BroadcastWebErrorProperties
     */
    'MessageID': string;
}

/**
    * @export
    * @enum {string}
    */
export enum BroadcastWebErrorPropertiesErrorSeverityEnum {
    Warn = 'warn',
    Error = 'error'
}
/**
    * @export
    * @enum {string}
    */
export enum BroadcastWebErrorPropertiesCurrentUserRoleEnum {
    Owner = 'owner',
    Coowner = 'coowner',
    Viewer = 'viewer',
    Invitee = 'invitee'
}

/**
 * 
 * @export
 * @interface BroadcastWebErrorPropertiesAllOf
 */
export interface BroadcastWebErrorPropertiesAllOf {
    /**
     * 
     * @type {string}
     * @memberof BroadcastWebErrorPropertiesAllOf
     */
    'ErrorSeverity': BroadcastWebErrorPropertiesAllOfErrorSeverityEnum;
    /**
     * 
     * @type {string}
     * @memberof BroadcastWebErrorPropertiesAllOf
     */
    'Message': string;
    /**
     * 
     * @type {string}
     * @memberof BroadcastWebErrorPropertiesAllOf
     */
    'Detail'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum BroadcastWebErrorPropertiesAllOfErrorSeverityEnum {
    Warn = 'warn',
    Error = 'error'
}

/**
 * 
 * @export
 * @interface ConversationProperties
 */
export interface ConversationProperties {
    /**
     * 
     * @type {string}
     * @memberof ConversationProperties
     */
    'ConversationID': string;
}
/**
 * 
 * @export
 * @interface EmailVerificationStatusResponse
 */
export interface EmailVerificationStatusResponse {
    /**
     * 
     * @type {boolean}
     * @memberof EmailVerificationStatusResponse
     */
    'email_verified': boolean;
}
/**
 * 
 * @export
 * @interface EmailVerificationSuccessResponse
 */
export interface EmailVerificationSuccessResponse {
    /**
     * 
     * @type {string}
     * @memberof EmailVerificationSuccessResponse
     */
    'status'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EmailVerificationSuccessResponse
     */
    'email_sent'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EmailVerificationSuccessResponse
     */
    'verification_session_id'?: string;
}
/**
 * 
 * @export
 * @interface EmailVerificationTrigger
 */
export interface EmailVerificationTrigger {
    /**
     * 
     * @type {string}
     * @memberof EmailVerificationTrigger
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof EmailVerificationTrigger
     */
    'web_user_id': string;
}
/**
 * 
 * @export
 * @interface EmptySuccessResponse
 */
export interface EmptySuccessResponse {
    /**
     * 
     * @type {string}
     * @memberof EmptySuccessResponse
     */
    'status'?: string;
}
/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    'message'?: string;
}
/**
 * @type EventBody
 * @export
 */
export type EventBody = BroadcastIntroPlay | BroadcastInviteInstall | BroadcastInviteView | BroadcastMessageInstall | BroadcastMessagePlay | BroadcastMessageView | BroadcastUpdatesEvent | BroadcastWebErrorEvent | NewPlay | PlayErrorEvent | PlaybackDone | ProShareMessageInstall | ProShareMessagePlay | ProShareMessageView | ProShareWebError | Replay | SecondsAlbumOpen | SecondsInviteInstall | SecondsPlay | SecondsPlayError | SecondsWebError;

/**
 * 
 * @export
 * @interface EventCommon
 */
export interface EventCommon {
    /**
     * 
     * @type {boolean}
     * @memberof EventCommon
     */
    'validate': boolean;
    /**
     * 
     * @type {string}
     * @memberof EventCommon
     */
    'device_id': string;
    /**
     * 
     * @type {number}
     * @memberof EventCommon
     */
    'event_time': number;
    /**
     * 
     * @type {string}
     * @memberof EventCommon
     */
    'user_id'?: string;
    /**
     * 
     * @type {EventCommonProperties}
     * @memberof EventCommon
     */
    'properties'?: EventCommonProperties;
}
/**
 * 
 * @export
 * @interface EventCommonProperties
 */
export interface EventCommonProperties {
    /**
     * 
     * @type {string}
     * @memberof EventCommonProperties
     */
    'OSNum': string;
}
/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    'phone': string;
}
/**
 * 
 * @export
 * @interface InlineResponse201
 */
export interface InlineResponse201 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse201
     */
    'status'?: string;
}
/**
 * 
 * @export
 * @interface InstallProperties
 */
export interface InstallProperties {
    /**
     * 
     * @type {string}
     * @memberof InstallProperties
     */
    'InstallPlatform': InstallPropertiesInstallPlatformEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum InstallPropertiesInstallPlatformEnum {
    Ios = 'ios',
    Android = 'android'
}

/**
 * 
 * @export
 * @interface Invite
 */
export interface Invite {
    /**
     * 
     * @type {string}
     * @memberof Invite
     */
    'web_invite_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Invite
     */
    'invite_link'?: string;
}
/**
 * 
 * @export
 * @interface MessageProperties
 */
export interface MessageProperties {
    /**
     * 
     * @type {string}
     * @memberof MessageProperties
     */
    'ConversationID': string;
    /**
     * 
     * @type {string}
     * @memberof MessageProperties
     */
    'MessageID': string;
}
/**
 * 
 * @export
 * @interface MessagePropertiesAnyOf
 */
export interface MessagePropertiesAnyOf {
    /**
     * 
     * @type {string}
     * @memberof MessagePropertiesAnyOf
     */
    'MessageID': string;
}
/**
 * 
 * @export
 * @interface NewPlay
 */
export interface NewPlay {
    /**
     * 
     * @type {boolean}
     * @memberof NewPlay
     */
    'validate': boolean;
    /**
     * 
     * @type {string}
     * @memberof NewPlay
     */
    'device_id': string;
    /**
     * 
     * @type {number}
     * @memberof NewPlay
     */
    'event_time': number;
    /**
     * 
     * @type {string}
     * @memberof NewPlay
     */
    'user_id'?: string;
    /**
     * 
     * @type {NewPlayProperties}
     * @memberof NewPlay
     */
    'properties': NewPlayProperties;
    /**
     * 
     * @type {Array<string>}
     * @memberof NewPlay
     */
    'projects': Array<NewPlayProjectsEnum>;
    /**
     * 
     * @type {string}
     * @memberof NewPlay
     */
    'event_name': NewPlayEventNameEnum;
}

/**
    * @export
    * Constant type enum
    */
export type NewPlayProjectsEnum = 'Activation';
/**
    * @export
    * Constant type enum
    */
export type NewPlayEventNameEnum = 'NEW PLAY';

/**
 * 
 * @export
 * @interface NewPlayAllOf
 */
export interface NewPlayAllOf {
    /**
     * 
     * @type {Array<string>}
     * @memberof NewPlayAllOf
     */
    'projects'?: Array<NewPlayAllOfProjectsEnum>;
    /**
     * 
     * @type {string}
     * @memberof NewPlayAllOf
     */
    'event_name': NewPlayAllOfEventNameEnum;
    /**
     * 
     * @type {NewPlayProperties}
     * @memberof NewPlayAllOf
     */
    'properties': NewPlayProperties;
}

/**
    * @export
    * Constant type enum
    */
export type NewPlayAllOfProjectsEnum = 'Activation';
/**
    * @export
    * Constant type enum
    */
export type NewPlayAllOfEventNameEnum = 'NEW PLAY';

/**
 * 
 * @export
 * @interface NewPlayProperties
 */
export interface NewPlayProperties {
    /**
     * 
     * @type {string}
     * @memberof NewPlayProperties
     */
    'ConversationID': string;
    /**
     * 
     * @type {string}
     * @memberof NewPlayProperties
     */
    'VideoID': string;
    /**
     * 
     * @type {boolean}
     * @memberof NewPlayProperties
     */
    'Broadcast': boolean;
    /**
     * 
     * @type {string}
     * @memberof NewPlayProperties
     */
    'CreatorUserId': string;
    /**
     * 
     * @type {number}
     * @memberof NewPlayProperties
     */
    'DaysSinceLastRP'?: number;
    /**
     * 
     * @type {number}
     * @memberof NewPlayProperties
     */
    'DaysSincePoloRecorded'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof NewPlayProperties
     */
    'Group': boolean;
    /**
     * 
     * @type {number}
     * @memberof NewPlayProperties
     */
    'MemberCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof NewPlayProperties
     */
    'Title': string;
}
/**
 * 
 * @export
 * @interface NewPlayPropertiesAllOf
 */
export interface NewPlayPropertiesAllOf {
    /**
     * 
     * @type {boolean}
     * @memberof NewPlayPropertiesAllOf
     */
    'Broadcast': boolean;
    /**
     * 
     * @type {string}
     * @memberof NewPlayPropertiesAllOf
     */
    'CreatorUserId': string;
    /**
     * 
     * @type {number}
     * @memberof NewPlayPropertiesAllOf
     */
    'DaysSinceLastRP'?: number;
    /**
     * 
     * @type {number}
     * @memberof NewPlayPropertiesAllOf
     */
    'DaysSincePoloRecorded'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof NewPlayPropertiesAllOf
     */
    'Group': boolean;
    /**
     * 
     * @type {number}
     * @memberof NewPlayPropertiesAllOf
     */
    'MemberCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof NewPlayPropertiesAllOf
     */
    'Title': string;
}
/**
 * 
 * @export
 * @interface NoteMessage
 */
export interface NoteMessage {
    /**
     * 
     * @type {boolean}
     * @memberof NoteMessage
     */
    'animate_text': boolean;
    /**
     * 
     * @type {number}
     * @memberof NoteMessage
     */
    'created_at': number;
    /**
     * 
     * @type {string}
     * @memberof NoteMessage
     */
    'message_id': string;
    /**
     * 
     * @type {string}
     * @memberof NoteMessage
     */
    'text': string;
    /**
     * 
     * @type {number}
     * @memberof NoteMessage
     */
    'text_background': number;
    /**
     * 
     * @type {boolean}
     * @memberof NoteMessage
     */
    'urgent': boolean;
}
/**
 * 
 * @export
 * @interface Phone
 */
export interface Phone {
    /**
     * 
     * @type {string}
     * @memberof Phone
     */
    'phone'?: string;
}
/**
 * 
 * @export
 * @interface PhoneVerificationBody
 */
export interface PhoneVerificationBody {
    /**
     * 
     * @type {string}
     * @memberof PhoneVerificationBody
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoneVerificationBody
     */
    'web_invitee_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoneVerificationBody
     */
    'phone'?: string;
}
/**
 * 
 * @export
 * @interface PhoneVerificationTrigger
 */
export interface PhoneVerificationTrigger {
    /**
     * 
     * @type {string}
     * @memberof PhoneVerificationTrigger
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoneVerificationTrigger
     */
    'web_invitee_id': string;
}
/**
 * Event fired when there\'s an error playing a video
 * @export
 * @interface PlayErrorEvent
 */
export interface PlayErrorEvent {
    /**
     * 
     * @type {boolean}
     * @memberof PlayErrorEvent
     */
    'validate': boolean;
    /**
     * 
     * @type {string}
     * @memberof PlayErrorEvent
     */
    'device_id': string;
    /**
     * 
     * @type {number}
     * @memberof PlayErrorEvent
     */
    'event_time': number;
    /**
     * 
     * @type {string}
     * @memberof PlayErrorEvent
     */
    'user_id'?: string;
    /**
     * 
     * @type {PlayErrorProperties}
     * @memberof PlayErrorEvent
     */
    'properties': PlayErrorProperties;
    /**
     * 
     * @type {Array<string>}
     * @memberof PlayErrorEvent
     */
    'projects': Array<PlayErrorEventProjectsEnum>;
    /**
     * 
     * @type {string}
     * @memberof PlayErrorEvent
     */
    'event_name': PlayErrorEventEventNameEnum;
}

/**
    * @export
    * Constant type enum
    */
export type PlayErrorEventProjectsEnum = 'Activation';
/**
    * @export
    * Constant type enum
    */
export type PlayErrorEventEventNameEnum = 'PLAY ERROR';

/**
 * 
 * @export
 * @interface PlayErrorEventAllOf
 */
export interface PlayErrorEventAllOf {
    /**
     * 
     * @type {Array<string>}
     * @memberof PlayErrorEventAllOf
     */
    'projects'?: Array<PlayErrorEventAllOfProjectsEnum>;
    /**
     * 
     * @type {string}
     * @memberof PlayErrorEventAllOf
     */
    'event_name': PlayErrorEventAllOfEventNameEnum;
    /**
     * 
     * @type {PlayErrorProperties}
     * @memberof PlayErrorEventAllOf
     */
    'properties': PlayErrorProperties;
}

/**
    * @export
    * Constant type enum
    */
export type PlayErrorEventAllOfProjectsEnum = 'Activation';
/**
    * @export
    * Constant type enum
    */
export type PlayErrorEventAllOfEventNameEnum = 'PLAY ERROR';

/**
 * 
 * @export
 * @interface PlayErrorProperties
 */
export interface PlayErrorProperties {
    /**
     * 
     * @type {string}
     * @memberof PlayErrorProperties
     */
    'ConversationID': string;
    /**
     * 
     * @type {string}
     * @memberof PlayErrorProperties
     */
    'VideoID': string;
    /**
     * 
     * @type {boolean}
     * @memberof PlayErrorProperties
     */
    'Background': boolean;
    /**
     * 
     * @type {string}
     * @memberof PlayErrorProperties
     */
    'ContentType': PlayErrorPropertiesContentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PlayErrorProperties
     */
    'Message': string;
    /**
     * 
     * @type {boolean}
     * @memberof PlayErrorProperties
     */
    'PhoneCall': boolean;
}

/**
    * @export
    * Constant type enum
    */
export type PlayErrorPropertiesContentTypeEnum = 'Standard';

/**
 * 
 * @export
 * @interface PlayErrorPropertiesAllOf
 */
export interface PlayErrorPropertiesAllOf {
    /**
     * 
     * @type {boolean}
     * @memberof PlayErrorPropertiesAllOf
     */
    'Background': boolean;
    /**
     * 
     * @type {string}
     * @memberof PlayErrorPropertiesAllOf
     */
    'ContentType': PlayErrorPropertiesAllOfContentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PlayErrorPropertiesAllOf
     */
    'Message': string;
    /**
     * 
     * @type {boolean}
     * @memberof PlayErrorPropertiesAllOf
     */
    'PhoneCall': boolean;
}

/**
    * @export
    * Constant type enum
    */
export type PlayErrorPropertiesAllOfContentTypeEnum = 'Standard';

/**
 * Event fired when a user finishes viewing an entire video message.  Also user in the mobile apps.
 * @export
 * @interface PlaybackDone
 */
export interface PlaybackDone {
    /**
     * 
     * @type {boolean}
     * @memberof PlaybackDone
     */
    'validate': boolean;
    /**
     * 
     * @type {string}
     * @memberof PlaybackDone
     */
    'device_id': string;
    /**
     * 
     * @type {number}
     * @memberof PlaybackDone
     */
    'event_time': number;
    /**
     * 
     * @type {string}
     * @memberof PlaybackDone
     */
    'user_id'?: string;
    /**
     * 
     * @type {PlaybackDoneProperties}
     * @memberof PlaybackDone
     */
    'properties': PlaybackDoneProperties;
    /**
     * 
     * @type {Array<string>}
     * @memberof PlaybackDone
     */
    'projects': Array<PlaybackDoneProjectsEnum>;
    /**
     * 
     * @type {string}
     * @memberof PlaybackDone
     */
    'event_name': PlaybackDoneEventNameEnum;
}

/**
    * @export
    * Constant type enum
    */
export type PlaybackDoneProjectsEnum = 'Activation';
/**
    * @export
    * Constant type enum
    */
export type PlaybackDoneEventNameEnum = 'PLAYBACK DONE';

/**
 * 
 * @export
 * @interface PlaybackDoneAllOf
 */
export interface PlaybackDoneAllOf {
    /**
     * 
     * @type {Array<string>}
     * @memberof PlaybackDoneAllOf
     */
    'projects'?: Array<PlaybackDoneAllOfProjectsEnum>;
    /**
     * 
     * @type {string}
     * @memberof PlaybackDoneAllOf
     */
    'event_name': PlaybackDoneAllOfEventNameEnum;
    /**
     * 
     * @type {PlaybackDoneProperties}
     * @memberof PlaybackDoneAllOf
     */
    'properties': PlaybackDoneProperties;
}

/**
    * @export
    * Constant type enum
    */
export type PlaybackDoneAllOfProjectsEnum = 'Activation';
/**
    * @export
    * Constant type enum
    */
export type PlaybackDoneAllOfEventNameEnum = 'PLAYBACK DONE';

/**
 * 
 * @export
 * @interface PlaybackDoneProperties
 */
export interface PlaybackDoneProperties {
    /**
     * 
     * @type {string}
     * @memberof PlaybackDoneProperties
     */
    'ConversationID': string;
    /**
     * 
     * @type {string}
     * @memberof PlaybackDoneProperties
     */
    'VideoID': string;
    /**
     * 
     * @type {string}
     * @memberof PlaybackDoneProperties
     */
    'CurrentUserRole': PlaybackDonePropertiesCurrentUserRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof PlaybackDoneProperties
     */
    'MessageID': string;
    /**
     * 
     * @type {boolean}
     * @memberof PlaybackDoneProperties
     */
    'Background': boolean;
    /**
     * 
     * @type {number}
     * @memberof PlaybackDoneProperties
     */
    'BytesBuffered'?: number;
    /**
     * 
     * @type {number}
     * @memberof PlaybackDoneProperties
     */
    'DownloadRate'?: number;
    /**
     * 
     * @type {string}
     * @memberof PlaybackDoneProperties
     */
    'DownStreamType'?: string;
    /**
     * 
     * @type {string}
     * @memberof PlaybackDoneProperties
     */
    'Emoji'?: string;
    /**
     * 
     * @type {string}
     * @memberof PlaybackDoneProperties
     */
    'EmojiSource'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PlaybackDoneProperties
     */
    'Group': boolean;
    /**
     * 
     * @type {number}
     * @memberof PlaybackDoneProperties
     */
    'MemberCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof PlaybackDoneProperties
     */
    'MessageId': string;
    /**
     * 
     * @type {string}
     * @memberof PlaybackDoneProperties
     */
    'Protocol'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PlaybackDoneProperties
     */
    'Scratchpad'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PlaybackDoneProperties
     */
    'TransmissionId'?: string;
    /**
     * 
     * @type {number}
     * @memberof PlaybackDoneProperties
     */
    'UploadRate'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PlaybackDoneProperties
     */
    'UsingCluster': boolean;
    /**
     * 
     * @type {string}
     * @memberof PlaybackDoneProperties
     */
    'VideoHost': string;
    /**
     * 
     * @type {string}
     * @memberof PlaybackDoneProperties
     */
    'VideoId': string;
}

/**
    * @export
    * @enum {string}
    */
export enum PlaybackDonePropertiesCurrentUserRoleEnum {
    Owner = 'owner',
    Coowner = 'coowner',
    Viewer = 'viewer',
    Invitee = 'invitee'
}

/**
 * 
 * @export
 * @interface PlaybackDonePropertiesAllOf
 */
export interface PlaybackDonePropertiesAllOf {
    /**
     * 
     * @type {boolean}
     * @memberof PlaybackDonePropertiesAllOf
     */
    'Background': boolean;
    /**
     * 
     * @type {number}
     * @memberof PlaybackDonePropertiesAllOf
     */
    'BytesBuffered'?: number;
    /**
     * 
     * @type {number}
     * @memberof PlaybackDonePropertiesAllOf
     */
    'DownloadRate'?: number;
    /**
     * 
     * @type {string}
     * @memberof PlaybackDonePropertiesAllOf
     */
    'DownStreamType'?: string;
    /**
     * 
     * @type {string}
     * @memberof PlaybackDonePropertiesAllOf
     */
    'Emoji'?: string;
    /**
     * 
     * @type {string}
     * @memberof PlaybackDonePropertiesAllOf
     */
    'EmojiSource'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PlaybackDonePropertiesAllOf
     */
    'Group': boolean;
    /**
     * 
     * @type {number}
     * @memberof PlaybackDonePropertiesAllOf
     */
    'MemberCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof PlaybackDonePropertiesAllOf
     */
    'MessageId': string;
    /**
     * 
     * @type {string}
     * @memberof PlaybackDonePropertiesAllOf
     */
    'Protocol'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PlaybackDonePropertiesAllOf
     */
    'Scratchpad'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PlaybackDonePropertiesAllOf
     */
    'TransmissionId'?: string;
    /**
     * 
     * @type {number}
     * @memberof PlaybackDonePropertiesAllOf
     */
    'UploadRate'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PlaybackDonePropertiesAllOf
     */
    'UsingCluster': boolean;
    /**
     * 
     * @type {string}
     * @memberof PlaybackDonePropertiesAllOf
     */
    'VideoHost': string;
    /**
     * 
     * @type {string}
     * @memberof PlaybackDonePropertiesAllOf
     */
    'VideoId': string;
}
/**
 * 
 * @export
 * @interface PlusPassPartnerInfo
 */
export interface PlusPassPartnerInfo {
    /**
     * 
     * @type {PlusPassPartnerInfoPartner}
     * @memberof PlusPassPartnerInfo
     */
    'partner': PlusPassPartnerInfoPartner;
    /**
     * 
     * @type {string}
     * @memberof PlusPassPartnerInfo
     */
    'partner_logo_url'?: string;
    /**
     * 
     * @type {number}
     * @memberof PlusPassPartnerInfo
     */
    'passes_remaining': number;
}
/**
 * 
 * @export
 * @interface PlusPassPartnerInfoPartner
 */
export interface PlusPassPartnerInfoPartner {
    /**
     * 
     * @type {string}
     * @memberof PlusPassPartnerInfoPartner
     */
    'partner_id': string;
    /**
     * 
     * @type {string}
     * @memberof PlusPassPartnerInfoPartner
     */
    'partner_name': string;
    /**
     * 
     * @type {string}
     * @memberof PlusPassPartnerInfoPartner
     */
    'partner_logo_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PlusPassPartnerInfoPartner
     */
    'partner_url_suffix': string;
    /**
     * 
     * @type {string}
     * @memberof PlusPassPartnerInfoPartner
     */
    'product_id': string;
    /**
     * 
     * @type {number}
     * @memberof PlusPassPartnerInfoPartner
     */
    'guest_pass_months': number;
    /**
     * 
     * @type {boolean}
     * @memberof PlusPassPartnerInfoPartner
     */
    'activated': boolean;
    /**
     * 
     * @type {number}
     * @memberof PlusPassPartnerInfoPartner
     */
    'activated_at': number;
}
/**
 * 
 * @export
 * @interface ProMessageResponse
 */
export interface ProMessageResponse {
    /**
     * 
     * @type {string}
     * @memberof ProMessageResponse
     */
    'conversation_id': string;
    /**
     * 
     * @type {string}
     * @memberof ProMessageResponse
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProMessageResponse
     */
    'thumbnail_url'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProMessageResponse
     */
    'over_limit_viewers': boolean;
    /**
     * 
     * @type {VideoMessage | NoteMessage}
     * @memberof ProMessageResponse
     */
    'message': VideoMessage | NoteMessage;
    /**
     * 
     * @type {User}
     * @memberof ProMessageResponse
     */
    'message_creator_info': User;
}
/**
 * Fires when a user clicks the Android or iOS install button on shared Polo Web playback page. The user may or may not complete install after clicking the button.
 * @export
 * @interface ProShareMessageInstall
 */
export interface ProShareMessageInstall {
    /**
     * 
     * @type {boolean}
     * @memberof ProShareMessageInstall
     */
    'validate': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProShareMessageInstall
     */
    'device_id': string;
    /**
     * 
     * @type {number}
     * @memberof ProShareMessageInstall
     */
    'event_time': number;
    /**
     * 
     * @type {string}
     * @memberof ProShareMessageInstall
     */
    'user_id'?: string;
    /**
     * 
     * @type {ProShareMessageInstallProperties}
     * @memberof ProShareMessageInstall
     */
    'properties': ProShareMessageInstallProperties;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProShareMessageInstall
     */
    'projects': Array<ProShareMessageInstallProjectsEnum>;
    /**
     * 
     * @type {string}
     * @memberof ProShareMessageInstall
     */
    'event_name': ProShareMessageInstallEventNameEnum;
}

/**
    * @export
    * Constant type enum
    */
export type ProShareMessageInstallProjectsEnum = 'Activation';
/**
    * @export
    * Constant type enum
    */
export type ProShareMessageInstallEventNameEnum = 'PRO SHARE MESSAGE INSTALL';

/**
 * 
 * @export
 * @interface ProShareMessageInstallAllOf
 */
export interface ProShareMessageInstallAllOf {
    /**
     * 
     * @type {Array<string>}
     * @memberof ProShareMessageInstallAllOf
     */
    'projects'?: Array<ProShareMessageInstallAllOfProjectsEnum>;
    /**
     * 
     * @type {string}
     * @memberof ProShareMessageInstallAllOf
     */
    'event_name': ProShareMessageInstallAllOfEventNameEnum;
    /**
     * 
     * @type {ProShareMessageInstallProperties}
     * @memberof ProShareMessageInstallAllOf
     */
    'properties': ProShareMessageInstallProperties;
}

/**
    * @export
    * Constant type enum
    */
export type ProShareMessageInstallAllOfProjectsEnum = 'Activation';
/**
    * @export
    * Constant type enum
    */
export type ProShareMessageInstallAllOfEventNameEnum = 'PRO SHARE MESSAGE INSTALL';

/**
 * 
 * @export
 * @interface ProShareMessageInstallProperties
 */
export interface ProShareMessageInstallProperties {
    /**
     * 
     * @type {string}
     * @memberof ProShareMessageInstallProperties
     */
    'InstallPlatform': ProShareMessageInstallPropertiesInstallPlatformEnum;
    /**
     * 
     * @type {string}
     * @memberof ProShareMessageInstallProperties
     */
    'ConversationID': string;
    /**
     * 
     * @type {string}
     * @memberof ProShareMessageInstallProperties
     */
    'MessageID': string;
}

/**
    * @export
    * @enum {string}
    */
export enum ProShareMessageInstallPropertiesInstallPlatformEnum {
    Ios = 'ios',
    Android = 'android'
}

/**
 * Fires when a user starts viewing a shared message video on the Web. This intentionally overlaps with the NEW PLAY event and provides more Pro Share feature-related details
 * @export
 * @interface ProShareMessagePlay
 */
export interface ProShareMessagePlay {
    /**
     * 
     * @type {boolean}
     * @memberof ProShareMessagePlay
     */
    'validate': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProShareMessagePlay
     */
    'device_id': string;
    /**
     * 
     * @type {number}
     * @memberof ProShareMessagePlay
     */
    'event_time': number;
    /**
     * 
     * @type {string}
     * @memberof ProShareMessagePlay
     */
    'user_id'?: string;
    /**
     * 
     * @type {ProShareMessageViewAllOfProperties}
     * @memberof ProShareMessagePlay
     */
    'properties': ProShareMessageViewAllOfProperties;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProShareMessagePlay
     */
    'projects': Array<ProShareMessagePlayProjectsEnum>;
    /**
     * 
     * @type {string}
     * @memberof ProShareMessagePlay
     */
    'event_name': ProShareMessagePlayEventNameEnum;
}

/**
    * @export
    * Constant type enum
    */
export type ProShareMessagePlayProjectsEnum = 'Activation';
/**
    * @export
    * Constant type enum
    */
export type ProShareMessagePlayEventNameEnum = 'PRO SHARE MESSAGE PLAY';

/**
 * 
 * @export
 * @interface ProShareMessagePlayAllOf
 */
export interface ProShareMessagePlayAllOf {
    /**
     * 
     * @type {Array<string>}
     * @memberof ProShareMessagePlayAllOf
     */
    'projects'?: Array<ProShareMessagePlayAllOfProjectsEnum>;
    /**
     * 
     * @type {string}
     * @memberof ProShareMessagePlayAllOf
     */
    'event_name': ProShareMessagePlayAllOfEventNameEnum;
    /**
     * 
     * @type {ProShareMessageViewAllOfProperties}
     * @memberof ProShareMessagePlayAllOf
     */
    'properties': ProShareMessageViewAllOfProperties;
}

/**
    * @export
    * Constant type enum
    */
export type ProShareMessagePlayAllOfProjectsEnum = 'Activation';
/**
    * @export
    * Constant type enum
    */
export type ProShareMessagePlayAllOfEventNameEnum = 'PRO SHARE MESSAGE PLAY';

/**
 * Fires when a user views the page with a shared Polo on the web.
 * @export
 * @interface ProShareMessageView
 */
export interface ProShareMessageView {
    /**
     * 
     * @type {boolean}
     * @memberof ProShareMessageView
     */
    'validate': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProShareMessageView
     */
    'device_id': string;
    /**
     * 
     * @type {number}
     * @memberof ProShareMessageView
     */
    'event_time': number;
    /**
     * 
     * @type {string}
     * @memberof ProShareMessageView
     */
    'user_id'?: string;
    /**
     * 
     * @type {ProShareMessageViewAllOfProperties}
     * @memberof ProShareMessageView
     */
    'properties': ProShareMessageViewAllOfProperties;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProShareMessageView
     */
    'projects': Array<ProShareMessageViewProjectsEnum>;
    /**
     * 
     * @type {string}
     * @memberof ProShareMessageView
     */
    'event_name': ProShareMessageViewEventNameEnum;
}

/**
    * @export
    * Constant type enum
    */
export type ProShareMessageViewProjectsEnum = 'Activation';
/**
    * @export
    * Constant type enum
    */
export type ProShareMessageViewEventNameEnum = 'PRO SHARE MESSAGE VIEW';

/**
 * 
 * @export
 * @interface ProShareMessageViewAllOf
 */
export interface ProShareMessageViewAllOf {
    /**
     * 
     * @type {Array<string>}
     * @memberof ProShareMessageViewAllOf
     */
    'projects'?: Array<ProShareMessageViewAllOfProjectsEnum>;
    /**
     * 
     * @type {string}
     * @memberof ProShareMessageViewAllOf
     */
    'event_name': ProShareMessageViewAllOfEventNameEnum;
    /**
     * 
     * @type {ProShareMessageViewAllOfProperties}
     * @memberof ProShareMessageViewAllOf
     */
    'properties': ProShareMessageViewAllOfProperties;
}

/**
    * @export
    * Constant type enum
    */
export type ProShareMessageViewAllOfProjectsEnum = 'Activation';
/**
    * @export
    * Constant type enum
    */
export type ProShareMessageViewAllOfEventNameEnum = 'PRO SHARE MESSAGE VIEW';

/**
 * 
 * @export
 * @interface ProShareMessageViewAllOfProperties
 */
export interface ProShareMessageViewAllOfProperties {
    /**
     * 
     * @type {string}
     * @memberof ProShareMessageViewAllOfProperties
     */
    'ConversationID': string;
    /**
     * 
     * @type {string}
     * @memberof ProShareMessageViewAllOfProperties
     */
    'MessageID': string;
}
/**
 * Fires when there\'s an error from the shared Polo playback web page.
 * @export
 * @interface ProShareWebError
 */
export interface ProShareWebError {
    /**
     * 
     * @type {boolean}
     * @memberof ProShareWebError
     */
    'validate': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProShareWebError
     */
    'device_id': string;
    /**
     * 
     * @type {number}
     * @memberof ProShareWebError
     */
    'event_time': number;
    /**
     * 
     * @type {string}
     * @memberof ProShareWebError
     */
    'user_id'?: string;
    /**
     * 
     * @type {BroadcastWebErrorPropertiesAllOf}
     * @memberof ProShareWebError
     */
    'properties': BroadcastWebErrorPropertiesAllOf;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProShareWebError
     */
    'projects': Array<ProShareWebErrorProjectsEnum>;
    /**
     * 
     * @type {string}
     * @memberof ProShareWebError
     */
    'event_name': ProShareWebErrorEventNameEnum;
}

/**
    * @export
    * Constant type enum
    */
export type ProShareWebErrorProjectsEnum = 'Activation';
/**
    * @export
    * Constant type enum
    */
export type ProShareWebErrorEventNameEnum = 'PRO SHARE WEB ERROR';

/**
 * 
 * @export
 * @interface ProShareWebErrorAllOf
 */
export interface ProShareWebErrorAllOf {
    /**
     * 
     * @type {Array<string>}
     * @memberof ProShareWebErrorAllOf
     */
    'projects'?: Array<ProShareWebErrorAllOfProjectsEnum>;
    /**
     * 
     * @type {string}
     * @memberof ProShareWebErrorAllOf
     */
    'event_name': ProShareWebErrorAllOfEventNameEnum;
    /**
     * 
     * @type {BroadcastWebErrorPropertiesAllOf}
     * @memberof ProShareWebErrorAllOf
     */
    'properties': BroadcastWebErrorPropertiesAllOf;
}

/**
    * @export
    * Constant type enum
    */
export type ProShareWebErrorAllOfProjectsEnum = 'Activation';
/**
    * @export
    * Constant type enum
    */
export type ProShareWebErrorAllOfEventNameEnum = 'PRO SHARE WEB ERROR';

/**
 * Event fired when a user stops viewing an video message, having viewed it previously.  The video may not have played to completion.  Also user in the mobile apps.
 * @export
 * @interface Replay
 */
export interface Replay {
    /**
     * 
     * @type {boolean}
     * @memberof Replay
     */
    'validate': boolean;
    /**
     * 
     * @type {string}
     * @memberof Replay
     */
    'device_id': string;
    /**
     * 
     * @type {number}
     * @memberof Replay
     */
    'event_time': number;
    /**
     * 
     * @type {string}
     * @memberof Replay
     */
    'user_id'?: string;
    /**
     * 
     * @type {ReplayProperties}
     * @memberof Replay
     */
    'properties': ReplayProperties;
    /**
     * 
     * @type {Array<string>}
     * @memberof Replay
     */
    'projects': Array<ReplayProjectsEnum>;
    /**
     * 
     * @type {string}
     * @memberof Replay
     */
    'event_name': ReplayEventNameEnum;
}

/**
    * @export
    * Constant type enum
    */
export type ReplayProjectsEnum = 'Activation';
/**
    * @export
    * Constant type enum
    */
export type ReplayEventNameEnum = 'REPLAY';

/**
 * 
 * @export
 * @interface ReplayAllOf
 */
export interface ReplayAllOf {
    /**
     * 
     * @type {Array<string>}
     * @memberof ReplayAllOf
     */
    'projects'?: Array<ReplayAllOfProjectsEnum>;
    /**
     * 
     * @type {string}
     * @memberof ReplayAllOf
     */
    'event_name': ReplayAllOfEventNameEnum;
    /**
     * 
     * @type {ReplayProperties}
     * @memberof ReplayAllOf
     */
    'properties': ReplayProperties;
}

/**
    * @export
    * Constant type enum
    */
export type ReplayAllOfProjectsEnum = 'Activation';
/**
    * @export
    * Constant type enum
    */
export type ReplayAllOfEventNameEnum = 'REPLAY';

/**
 * 
 * @export
 * @interface ReplayProperties
 */
export interface ReplayProperties {
    /**
     * 
     * @type {string}
     * @memberof ReplayProperties
     */
    'ConversationID': string;
    /**
     * 
     * @type {string}
     * @memberof ReplayProperties
     */
    'MessageID': string;
    /**
     * 
     * @type {boolean}
     * @memberof ReplayProperties
     */
    'Broadcast': boolean;
    /**
     * 
     * @type {string}
     * @memberof ReplayProperties
     */
    'ContentType'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReplayProperties
     */
    'DaysSincePoloRecorded': number;
    /**
     * 
     * @type {number}
     * @memberof ReplayProperties
     */
    'DownloadedBytes'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReplayProperties
     */
    'Duration': number;
    /**
     * 
     * @type {boolean}
     * @memberof ReplayProperties
     */
    'Group': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReplayProperties
     */
    'Owner': boolean;
    /**
     * 
     * @type {number}
     * @memberof ReplayProperties
     */
    'PlayCount': number;
    /**
     * 
     * @type {boolean}
     * @memberof ReplayProperties
     */
    'PlayedToEnd': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReplayProperties
     */
    'PreviouslyInterupted': boolean;
    /**
     * 
     * @type {number}
     * @memberof ReplayProperties
     */
    'TimeSinceLastMsgRecieved'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReplayProperties
     */
    'TimeSinceMsgCreated': number;
    /**
     * 
     * @type {string}
     * @memberof ReplayProperties
     */
    'MessageId': string;
}
/**
 * 
 * @export
 * @interface ReplayPropertiesAllOf
 */
export interface ReplayPropertiesAllOf {
    /**
     * 
     * @type {boolean}
     * @memberof ReplayPropertiesAllOf
     */
    'Broadcast': boolean;
    /**
     * 
     * @type {string}
     * @memberof ReplayPropertiesAllOf
     */
    'ContentType'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReplayPropertiesAllOf
     */
    'DaysSincePoloRecorded': number;
    /**
     * 
     * @type {number}
     * @memberof ReplayPropertiesAllOf
     */
    'DownloadedBytes'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReplayPropertiesAllOf
     */
    'Duration': number;
    /**
     * 
     * @type {boolean}
     * @memberof ReplayPropertiesAllOf
     */
    'Group': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReplayPropertiesAllOf
     */
    'Owner': boolean;
    /**
     * 
     * @type {number}
     * @memberof ReplayPropertiesAllOf
     */
    'PlayCount': number;
    /**
     * 
     * @type {boolean}
     * @memberof ReplayPropertiesAllOf
     */
    'PlayedToEnd': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReplayPropertiesAllOf
     */
    'PreviouslyInterupted': boolean;
    /**
     * 
     * @type {number}
     * @memberof ReplayPropertiesAllOf
     */
    'TimeSinceLastMsgRecieved'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReplayPropertiesAllOf
     */
    'TimeSinceMsgCreated': number;
    /**
     * 
     * @type {string}
     * @memberof ReplayPropertiesAllOf
     */
    'MessageId': string;
}
/**
 * 
 * @export
 * @interface ReportMessageWatchedErrors
 */
export interface ReportMessageWatchedErrors {
    /**
     * 
     * @type {ReportMessageWatchedErrorsError}
     * @memberof ReportMessageWatchedErrors
     */
    'error'?: ReportMessageWatchedErrorsError;
}
/**
 * 
 * @export
 * @interface ReportMessageWatchedErrorsError
 */
export interface ReportMessageWatchedErrorsError {
    /**
     * 
     * @type {string}
     * @memberof ReportMessageWatchedErrorsError
     */
    'code'?: ReportMessageWatchedErrorsErrorCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof ReportMessageWatchedErrorsError
     */
    'message'?: string;
}

/**
    * @export
    * Constant type enum
    */
export type ReportMessageWatchedErrorsErrorCodeEnum = 'NOT_IN_CONVERSATION';

/**
 * Event fired when a user opens a Seconds album
 * @export
 * @interface SecondsAlbumOpen
 */
export interface SecondsAlbumOpen {
    /**
     * 
     * @type {boolean}
     * @memberof SecondsAlbumOpen
     */
    'validate': boolean;
    /**
     * 
     * @type {string}
     * @memberof SecondsAlbumOpen
     */
    'device_id': string;
    /**
     * 
     * @type {number}
     * @memberof SecondsAlbumOpen
     */
    'event_time': number;
    /**
     * 
     * @type {string}
     * @memberof SecondsAlbumOpen
     */
    'user_id'?: string;
    /**
     * 
     * @type {SecondsAlbumOpenAllOfProperties}
     * @memberof SecondsAlbumOpen
     */
    'properties': SecondsAlbumOpenAllOfProperties;
    /**
     * 
     * @type {Array<string>}
     * @memberof SecondsAlbumOpen
     */
    'projects': Array<SecondsAlbumOpenProjectsEnum>;
    /**
     * 
     * @type {string}
     * @memberof SecondsAlbumOpen
     */
    'event_name': SecondsAlbumOpenEventNameEnum;
}

/**
    * @export
    * Constant type enum
    */
export type SecondsAlbumOpenProjectsEnum = 'Activation';
/**
    * @export
    * Constant type enum
    */
export type SecondsAlbumOpenEventNameEnum = 'SEC INVITE ALBUM OPEN';

/**
 * 
 * @export
 * @interface SecondsAlbumOpenAllOf
 */
export interface SecondsAlbumOpenAllOf {
    /**
     * 
     * @type {Array<string>}
     * @memberof SecondsAlbumOpenAllOf
     */
    'projects'?: Array<SecondsAlbumOpenAllOfProjectsEnum>;
    /**
     * 
     * @type {string}
     * @memberof SecondsAlbumOpenAllOf
     */
    'event_name': SecondsAlbumOpenAllOfEventNameEnum;
    /**
     * 
     * @type {SecondsAlbumOpenAllOfProperties}
     * @memberof SecondsAlbumOpenAllOf
     */
    'properties': SecondsAlbumOpenAllOfProperties;
}

/**
    * @export
    * Constant type enum
    */
export type SecondsAlbumOpenAllOfProjectsEnum = 'Activation';
/**
    * @export
    * Constant type enum
    */
export type SecondsAlbumOpenAllOfEventNameEnum = 'SEC INVITE ALBUM OPEN';

/**
 * 
 * @export
 * @interface SecondsAlbumOpenAllOfProperties
 */
export interface SecondsAlbumOpenAllOfProperties {
    /**
     * 
     * @type {string}
     * @memberof SecondsAlbumOpenAllOfProperties
     */
    'PublisherId': string;
}
/**
 * 
 * @export
 * @interface SecondsInstallProperties
 */
export interface SecondsInstallProperties {
    /**
     * 
     * @type {string}
     * @memberof SecondsInstallProperties
     */
    'InstallPlatform': SecondsInstallPropertiesInstallPlatformEnum;
    /**
     * 
     * @type {string}
     * @memberof SecondsInstallProperties
     */
    'ShareId': string;
    /**
     * 
     * @type {string}
     * @memberof SecondsInstallProperties
     */
    'PublisherId': string;
}

/**
    * @export
    * @enum {string}
    */
export enum SecondsInstallPropertiesInstallPlatformEnum {
    Ios = 'ios',
    Android = 'android'
}

/**
 * 
 * @export
 * @interface SecondsInstallPropertiesAllOf
 */
export interface SecondsInstallPropertiesAllOf {
    /**
     * 
     * @type {string}
     * @memberof SecondsInstallPropertiesAllOf
     */
    'ShareId': string;
    /**
     * 
     * @type {string}
     * @memberof SecondsInstallPropertiesAllOf
     */
    'PublisherId': string;
}
/**
 * Event fired when a user opens a Seconds album
 * @export
 * @interface SecondsInviteInstall
 */
export interface SecondsInviteInstall {
    /**
     * 
     * @type {boolean}
     * @memberof SecondsInviteInstall
     */
    'validate': boolean;
    /**
     * 
     * @type {string}
     * @memberof SecondsInviteInstall
     */
    'device_id': string;
    /**
     * 
     * @type {number}
     * @memberof SecondsInviteInstall
     */
    'event_time': number;
    /**
     * 
     * @type {string}
     * @memberof SecondsInviteInstall
     */
    'user_id'?: string;
    /**
     * 
     * @type {SecondsInstallProperties}
     * @memberof SecondsInviteInstall
     */
    'properties': SecondsInstallProperties;
    /**
     * 
     * @type {Array<string>}
     * @memberof SecondsInviteInstall
     */
    'projects': Array<SecondsInviteInstallProjectsEnum>;
    /**
     * 
     * @type {string}
     * @memberof SecondsInviteInstall
     */
    'event_name': SecondsInviteInstallEventNameEnum;
}

/**
    * @export
    * Constant type enum
    */
export type SecondsInviteInstallProjectsEnum = 'Activation';
/**
    * @export
    * Constant type enum
    */
export type SecondsInviteInstallEventNameEnum = 'SEC INVITE INSTALL CLICK';

/**
 * 
 * @export
 * @interface SecondsInviteInstallAllOf
 */
export interface SecondsInviteInstallAllOf {
    /**
     * 
     * @type {Array<string>}
     * @memberof SecondsInviteInstallAllOf
     */
    'projects'?: Array<SecondsInviteInstallAllOfProjectsEnum>;
    /**
     * 
     * @type {string}
     * @memberof SecondsInviteInstallAllOf
     */
    'event_name': SecondsInviteInstallAllOfEventNameEnum;
    /**
     * 
     * @type {SecondsInstallProperties}
     * @memberof SecondsInviteInstallAllOf
     */
    'properties': SecondsInstallProperties;
}

/**
    * @export
    * Constant type enum
    */
export type SecondsInviteInstallAllOfProjectsEnum = 'Activation';
/**
    * @export
    * Constant type enum
    */
export type SecondsInviteInstallAllOfEventNameEnum = 'SEC INVITE INSTALL CLICK';

/**
 * Event fired when starting to play a Second
 * @export
 * @interface SecondsPlay
 */
export interface SecondsPlay {
    /**
     * 
     * @type {boolean}
     * @memberof SecondsPlay
     */
    'validate': boolean;
    /**
     * 
     * @type {string}
     * @memberof SecondsPlay
     */
    'device_id': string;
    /**
     * 
     * @type {number}
     * @memberof SecondsPlay
     */
    'event_time': number;
    /**
     * 
     * @type {string}
     * @memberof SecondsPlay
     */
    'user_id'?: string;
    /**
     * 
     * @type {SecondsPlayProperties}
     * @memberof SecondsPlay
     */
    'properties': SecondsPlayProperties;
    /**
     * 
     * @type {Array<string>}
     * @memberof SecondsPlay
     */
    'projects': Array<SecondsPlayProjectsEnum>;
    /**
     * 
     * @type {string}
     * @memberof SecondsPlay
     */
    'event_name': SecondsPlayEventNameEnum;
}

/**
    * @export
    * Constant type enum
    */
export type SecondsPlayProjectsEnum = 'Activation';
/**
    * @export
    * Constant type enum
    */
export type SecondsPlayEventNameEnum = 'SEC INVITE ALBUM PLAY';

/**
 * 
 * @export
 * @interface SecondsPlayAllOf
 */
export interface SecondsPlayAllOf {
    /**
     * 
     * @type {Array<string>}
     * @memberof SecondsPlayAllOf
     */
    'projects'?: Array<SecondsPlayAllOfProjectsEnum>;
    /**
     * 
     * @type {string}
     * @memberof SecondsPlayAllOf
     */
    'event_name': SecondsPlayAllOfEventNameEnum;
    /**
     * 
     * @type {SecondsPlayProperties}
     * @memberof SecondsPlayAllOf
     */
    'properties': SecondsPlayProperties;
}

/**
    * @export
    * Constant type enum
    */
export type SecondsPlayAllOfProjectsEnum = 'Activation';
/**
    * @export
    * Constant type enum
    */
export type SecondsPlayAllOfEventNameEnum = 'SEC INVITE ALBUM PLAY';

/**
 * Event fired when there\'s an error playing a Second
 * @export
 * @interface SecondsPlayError
 */
export interface SecondsPlayError {
    /**
     * 
     * @type {boolean}
     * @memberof SecondsPlayError
     */
    'validate': boolean;
    /**
     * 
     * @type {string}
     * @memberof SecondsPlayError
     */
    'device_id': string;
    /**
     * 
     * @type {number}
     * @memberof SecondsPlayError
     */
    'event_time': number;
    /**
     * 
     * @type {string}
     * @memberof SecondsPlayError
     */
    'user_id'?: string;
    /**
     * 
     * @type {SecondsPlayErrorProperties}
     * @memberof SecondsPlayError
     */
    'properties': SecondsPlayErrorProperties;
    /**
     * 
     * @type {Array<string>}
     * @memberof SecondsPlayError
     */
    'projects': Array<SecondsPlayErrorProjectsEnum>;
    /**
     * 
     * @type {string}
     * @memberof SecondsPlayError
     */
    'event_name': SecondsPlayErrorEventNameEnum;
}

/**
    * @export
    * Constant type enum
    */
export type SecondsPlayErrorProjectsEnum = 'Activation';
/**
    * @export
    * Constant type enum
    */
export type SecondsPlayErrorEventNameEnum = 'SEC INVITE ALBUM PLAY ERROR';

/**
 * 
 * @export
 * @interface SecondsPlayErrorAllOf
 */
export interface SecondsPlayErrorAllOf {
    /**
     * 
     * @type {Array<string>}
     * @memberof SecondsPlayErrorAllOf
     */
    'projects'?: Array<SecondsPlayErrorAllOfProjectsEnum>;
    /**
     * 
     * @type {string}
     * @memberof SecondsPlayErrorAllOf
     */
    'event_name': SecondsPlayErrorAllOfEventNameEnum;
    /**
     * 
     * @type {SecondsPlayErrorProperties}
     * @memberof SecondsPlayErrorAllOf
     */
    'properties': SecondsPlayErrorProperties;
}

/**
    * @export
    * Constant type enum
    */
export type SecondsPlayErrorAllOfProjectsEnum = 'Activation';
/**
    * @export
    * Constant type enum
    */
export type SecondsPlayErrorAllOfEventNameEnum = 'SEC INVITE ALBUM PLAY ERROR';

/**
 * 
 * @export
 * @interface SecondsPlayErrorProperties
 */
export interface SecondsPlayErrorProperties {
    /**
     * 
     * @type {string}
     * @memberof SecondsPlayErrorProperties
     */
    'Sxid': string;
    /**
     * 
     * @type {string}
     * @memberof SecondsPlayErrorProperties
     */
    'PublisherId': string;
    /**
     * 
     * @type {string}
     * @memberof SecondsPlayErrorProperties
     */
    'Error': string;
}
/**
 * 
 * @export
 * @interface SecondsPlayErrorPropertiesAllOf
 */
export interface SecondsPlayErrorPropertiesAllOf {
    /**
     * 
     * @type {string}
     * @memberof SecondsPlayErrorPropertiesAllOf
     */
    'Sxid': string;
    /**
     * 
     * @type {string}
     * @memberof SecondsPlayErrorPropertiesAllOf
     */
    'PublisherId': string;
    /**
     * 
     * @type {string}
     * @memberof SecondsPlayErrorPropertiesAllOf
     */
    'Error': string;
}
/**
 * 
 * @export
 * @interface SecondsPlayProperties
 */
export interface SecondsPlayProperties {
    /**
     * 
     * @type {string}
     * @memberof SecondsPlayProperties
     */
    'Sxid': string;
    /**
     * 
     * @type {string}
     * @memberof SecondsPlayProperties
     */
    'PublisherId': string;
}
/**
 * 
 * @export
 * @interface SecondsShareInfo
 */
export interface SecondsShareInfo {
    /**
     * 
     * @type {string}
     * @memberof SecondsShareInfo
     */
    'id': string;
    /**
     * 
     * @type {User}
     * @memberof SecondsShareInfo
     */
    'creator': User;
    /**
     * 
     * @type {AppInstallLinks}
     * @memberof SecondsShareInfo
     */
    'install_links': AppInstallLinks;
    /**
     * 
     * @type {Array<SecondsShareInfoSeconds>}
     * @memberof SecondsShareInfo
     */
    'seconds': Array<SecondsShareInfoSeconds>;
    /**
     * 
     * @type {Array<SecondsShareInfoCards>}
     * @memberof SecondsShareInfo
     */
    'cards': Array<SecondsShareInfoCards>;
}
/**
 * 
 * @export
 * @interface SecondsShareInfoCards
 */
export interface SecondsShareInfoCards {
    /**
     * 
     * @type {string}
     * @memberof SecondsShareInfoCards
     */
    'card_id': string;
    /**
     * 
     * @type {number}
     * @memberof SecondsShareInfoCards
     */
    'added': number;
    /**
     * 
     * @type {string}
     * @memberof SecondsShareInfoCards
     */
    'text': string;
    /**
     * 
     * @type {number}
     * @memberof SecondsShareInfoCards
     */
    'color': number;
}
/**
 * 
 * @export
 * @interface SecondsShareInfoSeconds
 */
export interface SecondsShareInfoSeconds {
    /**
     * 
     * @type {string}
     * @memberof SecondsShareInfoSeconds
     */
    'second_id': string;
    /**
     * 
     * @type {number}
     * @memberof SecondsShareInfoSeconds
     */
    'added': number;
    /**
     * 
     * @type {string}
     * @memberof SecondsShareInfoSeconds
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof SecondsShareInfoSeconds
     */
    'thumbnail_url': string;
}
/**
 * Event fired when a user opens a Seconds album
 * @export
 * @interface SecondsWebError
 */
export interface SecondsWebError {
    /**
     * 
     * @type {boolean}
     * @memberof SecondsWebError
     */
    'validate': boolean;
    /**
     * 
     * @type {string}
     * @memberof SecondsWebError
     */
    'device_id': string;
    /**
     * 
     * @type {number}
     * @memberof SecondsWebError
     */
    'event_time': number;
    /**
     * 
     * @type {string}
     * @memberof SecondsWebError
     */
    'user_id'?: string;
    /**
     * 
     * @type {SecondsWebErrorAllOfProperties}
     * @memberof SecondsWebError
     */
    'properties': SecondsWebErrorAllOfProperties;
    /**
     * 
     * @type {Array<string>}
     * @memberof SecondsWebError
     */
    'projects': Array<SecondsWebErrorProjectsEnum>;
    /**
     * 
     * @type {string}
     * @memberof SecondsWebError
     */
    'event_name': SecondsWebErrorEventNameEnum;
}

/**
    * @export
    * Constant type enum
    */
export type SecondsWebErrorProjectsEnum = 'Activation';
/**
    * @export
    * Constant type enum
    */
export type SecondsWebErrorEventNameEnum = 'SEC WEB ERROR';

/**
 * 
 * @export
 * @interface SecondsWebErrorAllOf
 */
export interface SecondsWebErrorAllOf {
    /**
     * 
     * @type {Array<string>}
     * @memberof SecondsWebErrorAllOf
     */
    'projects'?: Array<SecondsWebErrorAllOfProjectsEnum>;
    /**
     * 
     * @type {string}
     * @memberof SecondsWebErrorAllOf
     */
    'event_name': SecondsWebErrorAllOfEventNameEnum;
    /**
     * 
     * @type {SecondsWebErrorAllOfProperties}
     * @memberof SecondsWebErrorAllOf
     */
    'properties': SecondsWebErrorAllOfProperties;
}

/**
    * @export
    * Constant type enum
    */
export type SecondsWebErrorAllOfProjectsEnum = 'Activation';
/**
    * @export
    * Constant type enum
    */
export type SecondsWebErrorAllOfEventNameEnum = 'SEC WEB ERROR';

/**
 * 
 * @export
 * @interface SecondsWebErrorAllOfProperties
 */
export interface SecondsWebErrorAllOfProperties {
    /**
     * 
     * @type {string}
     * @memberof SecondsWebErrorAllOfProperties
     */
    'ShareId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SecondsWebErrorAllOfProperties
     */
    'PublisherId': string;
    /**
     * 
     * @type {string}
     * @memberof SecondsWebErrorAllOfProperties
     */
    'ErrorSeverity': SecondsWebErrorAllOfPropertiesErrorSeverityEnum;
    /**
     * 
     * @type {string}
     * @memberof SecondsWebErrorAllOfProperties
     */
    'Message': string;
    /**
     * 
     * @type {string}
     * @memberof SecondsWebErrorAllOfProperties
     */
    'Detail'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum SecondsWebErrorAllOfPropertiesErrorSeverityEnum {
    Warn = 'warn',
    Error = 'error'
}

/**
 * 
 * @export
 * @interface SharecastSignupBody
 */
export interface SharecastSignupBody {
    /**
     * 
     * @type {string}
     * @memberof SharecastSignupBody
     */
    'web_user_id': string;
    /**
     * 
     * @type {string}
     * @memberof SharecastSignupBody
     */
    'web_invite_id': string;
    /**
     * 
     * @type {string}
     * @memberof SharecastSignupBody
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof SharecastSignupBody
     */
    'last_name': string;
    /**
     * 
     * @type {boolean}
     * @memberof SharecastSignupBody
     */
    'receive_sms_updates': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SharecastSignupBody
     */
    'receive_email_updates': boolean;
}
/**
 * 
 * @export
 * @interface SubscriptionStateBody
 */
export interface SubscriptionStateBody {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionStateBody
     */
    'web_invite_id': string;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionStateBody
     */
    'receive_sms_updates': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionStateBody
     */
    'receive_email_updates': boolean;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionStateBody
     */
    'verified_phone_at'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionStateBody
     */
    'verified_email_at'?: number | null;
}
/**
 * 
 * @export
 * @interface SubscriptionStateUpdatePayload
 */
export interface SubscriptionStateUpdatePayload {
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionStateUpdatePayload
     */
    'receive_sms_updates': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionStateUpdatePayload
     */
    'receive_email_updates': boolean;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'thumbnail_url'?: string;
}
/**
 * 
 * @export
 * @interface VerificationResponse
 */
export interface VerificationResponse {
    /**
     * 
     * @type {string}
     * @memberof VerificationResponse
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof VerificationResponse
     */
    'web_user_id': string;
}
/**
 * 
 * @export
 * @interface VerificationResponseErrors
 */
export interface VerificationResponseErrors {
    /**
     * 
     * @type {VerificationResponseErrorsError}
     * @memberof VerificationResponseErrors
     */
    'error'?: VerificationResponseErrorsError;
}
/**
 * 
 * @export
 * @interface VerificationResponseErrorsError
 */
export interface VerificationResponseErrorsError {
    /**
     * 
     * @type {string}
     * @memberof VerificationResponseErrorsError
     */
    'code'?: VerificationResponseErrorsErrorCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof VerificationResponseErrorsError
     */
    'message'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum VerificationResponseErrorsErrorCodeEnum {
    VerifyCodeNotFound = 'VERIFY_CODE_NOT_FOUND',
    VerifyCodeExpired = 'VERIFY_CODE_EXPIRED',
    VerifyCodeRetryLimit = 'VERIFY_CODE_RETRY_LIMIT',
    VerifyCodeDoesNotMatch = 'VERIFY_CODE_DOES_NOT_MATCH',
    ConversationInviteIneligible = 'CONVERSATION_INVITE_INELIGIBLE'
}

/**
 * 
 * @export
 * @interface VideoMessage
 */
export interface VideoMessage {
    /**
     * 
     * @type {string}
     * @memberof VideoMessage
     */
    'message_id': string;
    /**
     * 
     * @type {string}
     * @memberof VideoMessage
     */
    'thumbnail_url': string;
    /**
     * 
     * @type {string}
     * @memberof VideoMessage
     */
    'video_id': string;
    /**
     * 
     * @type {string}
     * @memberof VideoMessage
     */
    'video_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof VideoMessage
     */
    'image_url'?: string;
    /**
     * 
     * @type {number}
     * @memberof VideoMessage
     */
    'created_at': number;
    /**
     * 
     * @type {string}
     * @memberof VideoMessage
     */
    'auth_token'?: string;
    /**
     * 
     * @type {string}
     * @memberof VideoMessage
     */
    'read_token'?: string;
}
/**
 * 
 * @export
 * @interface VideoProperties
 */
export interface VideoProperties {
    /**
     * 
     * @type {string}
     * @memberof VideoProperties
     */
    'ConversationID': string;
    /**
     * 
     * @type {string}
     * @memberof VideoProperties
     */
    'VideoID': string;
}
/**
 * 
 * @export
 * @interface VideoPropertiesAllOf
 */
export interface VideoPropertiesAllOf {
    /**
     * 
     * @type {string}
     * @memberof VideoPropertiesAllOf
     */
    'VideoID': string;
}
/**
 * 
 * @export
 * @interface ViewingSessionBody
 */
export interface ViewingSessionBody {
    /**
     * 
     * @type {number}
     * @memberof ViewingSessionBody
     */
    'watch_time': number;
    /**
     * 
     * @type {string}
     * @memberof ViewingSessionBody
     */
    'latest_message': string;
    /**
     * 
     * @type {string}
     * @memberof ViewingSessionBody
     */
    'latest_message_watched': string;
}
/**
 * 
 * @export
 * @interface WebInviteeId
 */
export interface WebInviteeId {
    /**
     * 
     * @type {string}
     * @memberof WebInviteeId
     */
    'web_invitee_id'?: string;
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Authorizes the user
         * @param {AuthBody} authBody The payload required to authorize the invite
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authPost: async (authBody: AuthBody, options: Partial<MPAxiosRequestConfig> = {}): Promise<RequestArgs> => {
            // verify required parameter 'authBody' is not null or undefined
            assertParamExists('authPost', 'authBody', authBody)
            const localVarPath = `/auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authBody, localVarRequestOptions, configuration)

            // Add request's nickname to AxiosRequest config to be able to know the name of the method if it failed
            localVarRequestOptions.nickname = 'authPost';

            // Default failure severity is LOG, which means that failed request will only produce a log message.
            // Consider overriding it with WARNING or ERROR if failure needs to be surfaced to the user.
            localVarRequestOptions.failureSeverity = localVarRequestOptions.failureSeverity ?? RequestFailureSeverity.LOG;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Authorizes the user
         * @param {AuthBody} authBody The payload required to authorize the invite
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authPost(authBody: AuthBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authPost(authBody, options);
            return createRequestFunction<AuthResponse, AuthBody>(localVarAxiosArgs, globalAxios, BASE_PATH, 'AuthResponse', configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @summary Authorizes the user
         * @param {AuthBody} authBody The payload required to authorize the invite
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authPost(authBody: AuthBody, options?: Partial<MPAxiosRequestConfig>): AxiosPromise<AuthResponse> {
            return localVarFp.authPost(authBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for authPost operation in AuthApi.
 * @export
 * @interface AuthApiAuthPostRequest
 */
export interface AuthApiAuthPostRequest {
    /**
     * The payload required to authorize the invite
     * @type {AuthBody}
     * @memberof AuthApiAuthPost
     */
    readonly authBody: AuthBody
}

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @summary Authorizes the user
     * @param {AuthApiAuthPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authPost(requestParameters: AuthApiAuthPostRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authPost(requestParameters.authBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BroadcastLinksApi - axios parameter creator
 * @export
 */
export const BroadcastLinksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets invites for sharecast link
         * @param {string} inviteCode The ID for the broadcast invite
         * @param {WebInviteeId} webInviteeId the id of the user invited to join the sharecast
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        broadcastLinksInvitesInviteCodePost: async (inviteCode: string, webInviteeId: WebInviteeId, options: Partial<MPAxiosRequestConfig> = {}): Promise<RequestArgs> => {
            // verify required parameter 'inviteCode' is not null or undefined
            assertParamExists('broadcastLinksInvitesInviteCodePost', 'inviteCode', inviteCode)
            // verify required parameter 'webInviteeId' is not null or undefined
            assertParamExists('broadcastLinksInvitesInviteCodePost', 'webInviteeId', webInviteeId)
            const localVarPath = `/broadcast-links/invites/{invite_code}`
                .replace(`{${"invite_code"}}`, encodeURIComponent(String(inviteCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(webInviteeId, localVarRequestOptions, configuration)

            // Add request's nickname to AxiosRequest config to be able to know the name of the method if it failed
            localVarRequestOptions.nickname = 'broadcastLinksInvitesInviteCodePost';

            // Default failure severity is LOG, which means that failed request will only produce a log message.
            // Consider overriding it with WARNING or ERROR if failure needs to be surfaced to the user.
            localVarRequestOptions.failureSeverity = localVarRequestOptions.failureSeverity ?? RequestFailureSeverity.LOG;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary retrieve message information
         * @param {string} messageCode The code identifying the message
         * @param {BroadcastMessageBody} [broadcastMessageBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        broadcastLinksMessagesMessageCodePost: async (messageCode: string, broadcastMessageBody?: BroadcastMessageBody, options: Partial<MPAxiosRequestConfig> = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageCode' is not null or undefined
            assertParamExists('broadcastLinksMessagesMessageCodePost', 'messageCode', messageCode)
            const localVarPath = `/broadcast-links/messages/{message_code}`
                .replace(`{${"message_code"}}`, encodeURIComponent(String(messageCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(broadcastMessageBody, localVarRequestOptions, configuration)

            // Add request's nickname to AxiosRequest config to be able to know the name of the method if it failed
            localVarRequestOptions.nickname = 'broadcastLinksMessagesMessageCodePost';

            // Default failure severity is LOG, which means that failed request will only produce a log message.
            // Consider overriding it with WARNING or ERROR if failure needs to be surfaced to the user.
            localVarRequestOptions.failureSeverity = localVarRequestOptions.failureSeverity ?? RequestFailureSeverity.LOG;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets invites for sharecast link
         * @param {string} groupshare The ID for the broadcast-links group
         * @param {WebInviteeId} webInviteeId the id of the user invited to join the sharecast
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        broadcastLinksSharecastsGroupsharePost: async (groupshare: string, webInviteeId: WebInviteeId, options: Partial<MPAxiosRequestConfig> = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupshare' is not null or undefined
            assertParamExists('broadcastLinksSharecastsGroupsharePost', 'groupshare', groupshare)
            // verify required parameter 'webInviteeId' is not null or undefined
            assertParamExists('broadcastLinksSharecastsGroupsharePost', 'webInviteeId', webInviteeId)
            const localVarPath = `/broadcast-links/sharecasts/{groupshare}`
                .replace(`{${"groupshare"}}`, encodeURIComponent(String(groupshare)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(webInviteeId, localVarRequestOptions, configuration)

            // Add request's nickname to AxiosRequest config to be able to know the name of the method if it failed
            localVarRequestOptions.nickname = 'broadcastLinksSharecastsGroupsharePost';

            // Default failure severity is LOG, which means that failed request will only produce a log message.
            // Consider overriding it with WARNING or ERROR if failure needs to be surfaced to the user.
            localVarRequestOptions.failureSeverity = localVarRequestOptions.failureSeverity ?? RequestFailureSeverity.LOG;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BroadcastLinksApi - functional programming interface
 * @export
 */
export const BroadcastLinksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BroadcastLinksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets invites for sharecast link
         * @param {string} inviteCode The ID for the broadcast invite
         * @param {WebInviteeId} webInviteeId the id of the user invited to join the sharecast
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async broadcastLinksInvitesInviteCodePost(inviteCode: string, webInviteeId: WebInviteeId, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BroadcastLinksResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.broadcastLinksInvitesInviteCodePost(inviteCode, webInviteeId, options);
            return createRequestFunction<BroadcastLinksResponse, string>(localVarAxiosArgs, globalAxios, BASE_PATH, 'BroadcastLinksResponse', configuration);
        },
        /**
         * 
         * @summary retrieve message information
         * @param {string} messageCode The code identifying the message
         * @param {BroadcastMessageBody} [broadcastMessageBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async broadcastLinksMessagesMessageCodePost(messageCode: string, broadcastMessageBody?: BroadcastMessageBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BroadcastMessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.broadcastLinksMessagesMessageCodePost(messageCode, broadcastMessageBody, options);
            return createRequestFunction<BroadcastMessageResponse, string>(localVarAxiosArgs, globalAxios, BASE_PATH, 'BroadcastMessageResponse', configuration);
        },
        /**
         * 
         * @summary Gets invites for sharecast link
         * @param {string} groupshare The ID for the broadcast-links group
         * @param {WebInviteeId} webInviteeId the id of the user invited to join the sharecast
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async broadcastLinksSharecastsGroupsharePost(groupshare: string, webInviteeId: WebInviteeId, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BroadcastLinksResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.broadcastLinksSharecastsGroupsharePost(groupshare, webInviteeId, options);
            return createRequestFunction<BroadcastLinksResponse, string>(localVarAxiosArgs, globalAxios, BASE_PATH, 'BroadcastLinksResponse', configuration);
        },
    }
};

/**
 * BroadcastLinksApi - factory interface
 * @export
 */
export const BroadcastLinksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BroadcastLinksApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets invites for sharecast link
         * @param {string} inviteCode The ID for the broadcast invite
         * @param {WebInviteeId} webInviteeId the id of the user invited to join the sharecast
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        broadcastLinksInvitesInviteCodePost(inviteCode: string, webInviteeId: WebInviteeId, options?: Partial<MPAxiosRequestConfig>): AxiosPromise<BroadcastLinksResponse> {
            return localVarFp.broadcastLinksInvitesInviteCodePost(inviteCode, webInviteeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary retrieve message information
         * @param {string} messageCode The code identifying the message
         * @param {BroadcastMessageBody} [broadcastMessageBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        broadcastLinksMessagesMessageCodePost(messageCode: string, broadcastMessageBody?: BroadcastMessageBody, options?: Partial<MPAxiosRequestConfig>): AxiosPromise<BroadcastMessageResponse> {
            return localVarFp.broadcastLinksMessagesMessageCodePost(messageCode, broadcastMessageBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets invites for sharecast link
         * @param {string} groupshare The ID for the broadcast-links group
         * @param {WebInviteeId} webInviteeId the id of the user invited to join the sharecast
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        broadcastLinksSharecastsGroupsharePost(groupshare: string, webInviteeId: WebInviteeId, options?: Partial<MPAxiosRequestConfig>): AxiosPromise<BroadcastLinksResponse> {
            return localVarFp.broadcastLinksSharecastsGroupsharePost(groupshare, webInviteeId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for broadcastLinksInvitesInviteCodePost operation in BroadcastLinksApi.
 * @export
 * @interface BroadcastLinksApiBroadcastLinksInvitesInviteCodePostRequest
 */
export interface BroadcastLinksApiBroadcastLinksInvitesInviteCodePostRequest {
    /**
     * The ID for the broadcast invite
     * @type {string}
     * @memberof BroadcastLinksApiBroadcastLinksInvitesInviteCodePost
     */
    readonly inviteCode: string

    /**
     * the id of the user invited to join the sharecast
     * @type {WebInviteeId}
     * @memberof BroadcastLinksApiBroadcastLinksInvitesInviteCodePost
     */
    readonly webInviteeId: WebInviteeId
}

/**
 * Request parameters for broadcastLinksMessagesMessageCodePost operation in BroadcastLinksApi.
 * @export
 * @interface BroadcastLinksApiBroadcastLinksMessagesMessageCodePostRequest
 */
export interface BroadcastLinksApiBroadcastLinksMessagesMessageCodePostRequest {
    /**
     * The code identifying the message
     * @type {string}
     * @memberof BroadcastLinksApiBroadcastLinksMessagesMessageCodePost
     */
    readonly messageCode: string

    /**
     * 
     * @type {BroadcastMessageBody}
     * @memberof BroadcastLinksApiBroadcastLinksMessagesMessageCodePost
     */
    readonly broadcastMessageBody?: BroadcastMessageBody
}

/**
 * Request parameters for broadcastLinksSharecastsGroupsharePost operation in BroadcastLinksApi.
 * @export
 * @interface BroadcastLinksApiBroadcastLinksSharecastsGroupsharePostRequest
 */
export interface BroadcastLinksApiBroadcastLinksSharecastsGroupsharePostRequest {
    /**
     * The ID for the broadcast-links group
     * @type {string}
     * @memberof BroadcastLinksApiBroadcastLinksSharecastsGroupsharePost
     */
    readonly groupshare: string

    /**
     * the id of the user invited to join the sharecast
     * @type {WebInviteeId}
     * @memberof BroadcastLinksApiBroadcastLinksSharecastsGroupsharePost
     */
    readonly webInviteeId: WebInviteeId
}

/**
 * BroadcastLinksApi - object-oriented interface
 * @export
 * @class BroadcastLinksApi
 * @extends {BaseAPI}
 */
export class BroadcastLinksApi extends BaseAPI {
    /**
     * 
     * @summary Gets invites for sharecast link
     * @param {BroadcastLinksApiBroadcastLinksInvitesInviteCodePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BroadcastLinksApi
     */
    public broadcastLinksInvitesInviteCodePost(requestParameters: BroadcastLinksApiBroadcastLinksInvitesInviteCodePostRequest, options?: AxiosRequestConfig) {
        return BroadcastLinksApiFp(this.configuration).broadcastLinksInvitesInviteCodePost(requestParameters.inviteCode, requestParameters.webInviteeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary retrieve message information
     * @param {BroadcastLinksApiBroadcastLinksMessagesMessageCodePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BroadcastLinksApi
     */
    public broadcastLinksMessagesMessageCodePost(requestParameters: BroadcastLinksApiBroadcastLinksMessagesMessageCodePostRequest, options?: AxiosRequestConfig) {
        return BroadcastLinksApiFp(this.configuration).broadcastLinksMessagesMessageCodePost(requestParameters.messageCode, requestParameters.broadcastMessageBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets invites for sharecast link
     * @param {BroadcastLinksApiBroadcastLinksSharecastsGroupsharePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BroadcastLinksApi
     */
    public broadcastLinksSharecastsGroupsharePost(requestParameters: BroadcastLinksApiBroadcastLinksSharecastsGroupsharePostRequest, options?: AxiosRequestConfig) {
        return BroadcastLinksApiFp(this.configuration).broadcastLinksSharecastsGroupsharePost(requestParameters.groupshare, requestParameters.webInviteeId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BroadcastsApi - axios parameter creator
 * @export
 */
export const BroadcastsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates an invite for a broadcast
         * @param {string} conversationId The ID for the broadcast
         * @param {WebInviteeId} webInviteeId The Id of the person invited to the Sharecast
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        broadcastsConversationIdInvitesPost: async (conversationId: string, webInviteeId: WebInviteeId, options: Partial<MPAxiosRequestConfig> = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('broadcastsConversationIdInvitesPost', 'conversationId', conversationId)
            // verify required parameter 'webInviteeId' is not null or undefined
            assertParamExists('broadcastsConversationIdInvitesPost', 'webInviteeId', webInviteeId)
            const localVarPath = `/broadcasts/{conversation_id}/invites`
                .replace(`{${"conversation_id"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(webInviteeId, localVarRequestOptions, configuration)

            // Add request's nickname to AxiosRequest config to be able to know the name of the method if it failed
            localVarRequestOptions.nickname = 'broadcastsConversationIdInvitesPost';

            // Default failure severity is LOG, which means that failed request will only produce a log message.
            // Consider overriding it with WARNING or ERROR if failure needs to be surfaced to the user.
            localVarRequestOptions.failureSeverity = localVarRequestOptions.failureSeverity ?? RequestFailureSeverity.LOG;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Triggers phone verification
         * @param {string} conversationId The ID for the Sharecast
         * @param {string} webInviteId The ID for the Sharecast invite
         * @param {PhoneVerificationTrigger} phoneVerificationTrigger The phone number the invite should be sent to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        broadcastsConversationIdInvitesWebInviteIdSendCodePhonePost: async (conversationId: string, webInviteId: string, phoneVerificationTrigger: PhoneVerificationTrigger, options: Partial<MPAxiosRequestConfig> = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('broadcastsConversationIdInvitesWebInviteIdSendCodePhonePost', 'conversationId', conversationId)
            // verify required parameter 'webInviteId' is not null or undefined
            assertParamExists('broadcastsConversationIdInvitesWebInviteIdSendCodePhonePost', 'webInviteId', webInviteId)
            // verify required parameter 'phoneVerificationTrigger' is not null or undefined
            assertParamExists('broadcastsConversationIdInvitesWebInviteIdSendCodePhonePost', 'phoneVerificationTrigger', phoneVerificationTrigger)
            const localVarPath = `/broadcasts/{conversation_id}/invites/{web_invite_id}/send-code/phone`
                .replace(`{${"conversation_id"}}`, encodeURIComponent(String(conversationId)))
                .replace(`{${"web_invite_id"}}`, encodeURIComponent(String(webInviteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(phoneVerificationTrigger, localVarRequestOptions, configuration)

            // Add request's nickname to AxiosRequest config to be able to know the name of the method if it failed
            localVarRequestOptions.nickname = 'broadcastsConversationIdInvitesWebInviteIdSendCodePhonePost';

            // Default failure severity is LOG, which means that failed request will only produce a log message.
            // Consider overriding it with WARNING or ERROR if failure needs to be surfaced to the user.
            localVarRequestOptions.failureSeverity = localVarRequestOptions.failureSeverity ?? RequestFailureSeverity.LOG;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Triggers email verification
         * @param {string} conversationId The ID for the Sharecast
         * @param {string} webInviteId The ID for the Sharecast invite
         * @param {EmailVerificationTrigger} emailVerificationTrigger The required info to verify the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        broadcastsConversationIdInvitesWebInviteIdSendVerificationEmailPost: async (conversationId: string, webInviteId: string, emailVerificationTrigger: EmailVerificationTrigger, options: Partial<MPAxiosRequestConfig> = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('broadcastsConversationIdInvitesWebInviteIdSendVerificationEmailPost', 'conversationId', conversationId)
            // verify required parameter 'webInviteId' is not null or undefined
            assertParamExists('broadcastsConversationIdInvitesWebInviteIdSendVerificationEmailPost', 'webInviteId', webInviteId)
            // verify required parameter 'emailVerificationTrigger' is not null or undefined
            assertParamExists('broadcastsConversationIdInvitesWebInviteIdSendVerificationEmailPost', 'emailVerificationTrigger', emailVerificationTrigger)
            const localVarPath = `/broadcasts/{conversation_id}/invites/{web_invite_id}/send-verification/email`
                .replace(`{${"conversation_id"}}`, encodeURIComponent(String(conversationId)))
                .replace(`{${"web_invite_id"}}`, encodeURIComponent(String(webInviteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailVerificationTrigger, localVarRequestOptions, configuration)

            // Add request's nickname to AxiosRequest config to be able to know the name of the method if it failed
            localVarRequestOptions.nickname = 'broadcastsConversationIdInvitesWebInviteIdSendVerificationEmailPost';

            // Default failure severity is LOG, which means that failed request will only produce a log message.
            // Consider overriding it with WARNING or ERROR if failure needs to be surfaced to the user.
            localVarRequestOptions.failureSeverity = localVarRequestOptions.failureSeverity ?? RequestFailureSeverity.LOG;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Triggers email verification
         * @param {string} conversationId The ID for the Sharecast
         * @param {string} webInviteId The ID for the Sharecast invite
         * @param {PhoneVerificationBody} phoneVerificationBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        broadcastsConversationIdInvitesWebInviteIdVerifyCodePhonePost: async (conversationId: string, webInviteId: string, phoneVerificationBody: PhoneVerificationBody, options: Partial<MPAxiosRequestConfig> = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('broadcastsConversationIdInvitesWebInviteIdVerifyCodePhonePost', 'conversationId', conversationId)
            // verify required parameter 'webInviteId' is not null or undefined
            assertParamExists('broadcastsConversationIdInvitesWebInviteIdVerifyCodePhonePost', 'webInviteId', webInviteId)
            // verify required parameter 'phoneVerificationBody' is not null or undefined
            assertParamExists('broadcastsConversationIdInvitesWebInviteIdVerifyCodePhonePost', 'phoneVerificationBody', phoneVerificationBody)
            const localVarPath = `/broadcasts/{conversation_id}/invites/{web_invite_id}/verify-code/phone`
                .replace(`{${"conversation_id"}}`, encodeURIComponent(String(conversationId)))
                .replace(`{${"web_invite_id"}}`, encodeURIComponent(String(webInviteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(phoneVerificationBody, localVarRequestOptions, configuration)

            // Add request's nickname to AxiosRequest config to be able to know the name of the method if it failed
            localVarRequestOptions.nickname = 'broadcastsConversationIdInvitesWebInviteIdVerifyCodePhonePost';

            // Default failure severity is LOG, which means that failed request will only produce a log message.
            // Consider overriding it with WARNING or ERROR if failure needs to be surfaced to the user.
            localVarRequestOptions.failureSeverity = localVarRequestOptions.failureSeverity ?? RequestFailureSeverity.LOG;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Notifies the server that the user has watched a Sharecast message
         * @summary Reports Sharecast message as watched
         * @param {string} conversationId The ID for the Sharecast
         * @param {string} messageId The ID of the Sharecast message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        broadcastsConversationIdMessagesMessageIdWatchedPost: async (conversationId: string, messageId: string, options: Partial<MPAxiosRequestConfig> = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('broadcastsConversationIdMessagesMessageIdWatchedPost', 'conversationId', conversationId)
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('broadcastsConversationIdMessagesMessageIdWatchedPost', 'messageId', messageId)
            const localVarPath = `/broadcasts/{conversation_id}/messages/{message_id}/watched`
                .replace(`{${"conversation_id"}}`, encodeURIComponent(String(conversationId)))
                .replace(`{${"message_id"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            // Add request's nickname to AxiosRequest config to be able to know the name of the method if it failed
            localVarRequestOptions.nickname = 'broadcastsConversationIdMessagesMessageIdWatchedPost';

            // Default failure severity is LOG, which means that failed request will only produce a log message.
            // Consider overriding it with WARNING or ERROR if failure needs to be surfaced to the user.
            localVarRequestOptions.failureSeverity = localVarRequestOptions.failureSeverity ?? RequestFailureSeverity.LOG;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Signup for Sharecast updates
         * @param {string} conversationId The ID for the Sharecast
         * @param {SharecastSignupBody} sharecastSignupBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        broadcastsConversationIdSubscriptionsPost: async (conversationId: string, sharecastSignupBody: SharecastSignupBody, options: Partial<MPAxiosRequestConfig> = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('broadcastsConversationIdSubscriptionsPost', 'conversationId', conversationId)
            // verify required parameter 'sharecastSignupBody' is not null or undefined
            assertParamExists('broadcastsConversationIdSubscriptionsPost', 'sharecastSignupBody', sharecastSignupBody)
            const localVarPath = `/broadcasts/{conversation_id}/subscriptions`
                .replace(`{${"conversation_id"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sharecastSignupBody, localVarRequestOptions, configuration)

            // Add request's nickname to AxiosRequest config to be able to know the name of the method if it failed
            localVarRequestOptions.nickname = 'broadcastsConversationIdSubscriptionsPost';

            // Default failure severity is LOG, which means that failed request will only produce a log message.
            // Consider overriding it with WARNING or ERROR if failure needs to be surfaced to the user.
            localVarRequestOptions.failureSeverity = localVarRequestOptions.failureSeverity ?? RequestFailureSeverity.LOG;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve subscription state for user
         * @param {string} conversationId The ID for the Sharecast
         * @param {string} webUserId The user\&#39;s ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        broadcastsConversationIdSubscriptionsWebUserIdGet: async (conversationId: string, webUserId: string, options: Partial<MPAxiosRequestConfig> = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('broadcastsConversationIdSubscriptionsWebUserIdGet', 'conversationId', conversationId)
            // verify required parameter 'webUserId' is not null or undefined
            assertParamExists('broadcastsConversationIdSubscriptionsWebUserIdGet', 'webUserId', webUserId)
            const localVarPath = `/broadcasts/{conversation_id}/subscriptions/{web_user_id}`
                .replace(`{${"conversation_id"}}`, encodeURIComponent(String(conversationId)))
                .replace(`{${"web_user_id"}}`, encodeURIComponent(String(webUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            // Add request's nickname to AxiosRequest config to be able to know the name of the method if it failed
            localVarRequestOptions.nickname = 'broadcastsConversationIdSubscriptionsWebUserIdGet';

            // Default failure severity is LOG, which means that failed request will only produce a log message.
            // Consider overriding it with WARNING or ERROR if failure needs to be surfaced to the user.
            localVarRequestOptions.failureSeverity = localVarRequestOptions.failureSeverity ?? RequestFailureSeverity.LOG;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update subscription settings
         * @param {string} conversationId The ID for the Sharecast
         * @param {string} webUserId The user\&#39;s ID
         * @param {SubscriptionStateUpdatePayload} subscriptionStateUpdatePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        broadcastsConversationIdSubscriptionsWebUserIdPatch: async (conversationId: string, webUserId: string, subscriptionStateUpdatePayload: SubscriptionStateUpdatePayload, options: Partial<MPAxiosRequestConfig> = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('broadcastsConversationIdSubscriptionsWebUserIdPatch', 'conversationId', conversationId)
            // verify required parameter 'webUserId' is not null or undefined
            assertParamExists('broadcastsConversationIdSubscriptionsWebUserIdPatch', 'webUserId', webUserId)
            // verify required parameter 'subscriptionStateUpdatePayload' is not null or undefined
            assertParamExists('broadcastsConversationIdSubscriptionsWebUserIdPatch', 'subscriptionStateUpdatePayload', subscriptionStateUpdatePayload)
            const localVarPath = `/broadcasts/{conversation_id}/subscriptions/{web_user_id}`
                .replace(`{${"conversation_id"}}`, encodeURIComponent(String(conversationId)))
                .replace(`{${"web_user_id"}}`, encodeURIComponent(String(webUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(subscriptionStateUpdatePayload, localVarRequestOptions, configuration)

            // Add request's nickname to AxiosRequest config to be able to know the name of the method if it failed
            localVarRequestOptions.nickname = 'broadcastsConversationIdSubscriptionsWebUserIdPatch';

            // Default failure severity is LOG, which means that failed request will only produce a log message.
            // Consider overriding it with WARNING or ERROR if failure needs to be surfaced to the user.
            localVarRequestOptions.failureSeverity = localVarRequestOptions.failureSeverity ?? RequestFailureSeverity.LOG;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Reports the start of Sharecast message viewing session
         * @summary Reports viewing session of Sharecast message
         * @param {string} conversationId The ID for the Sharecast
         * @param {ViewingSessionBody} viewingSessionBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        broadcastsConversationIdViewingSessionPost: async (conversationId: string, viewingSessionBody: ViewingSessionBody, options: Partial<MPAxiosRequestConfig> = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('broadcastsConversationIdViewingSessionPost', 'conversationId', conversationId)
            // verify required parameter 'viewingSessionBody' is not null or undefined
            assertParamExists('broadcastsConversationIdViewingSessionPost', 'viewingSessionBody', viewingSessionBody)
            const localVarPath = `/broadcasts/{conversation_id}/viewing-session`
                .replace(`{${"conversation_id"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(viewingSessionBody, localVarRequestOptions, configuration)

            // Add request's nickname to AxiosRequest config to be able to know the name of the method if it failed
            localVarRequestOptions.nickname = 'broadcastsConversationIdViewingSessionPost';

            // Default failure severity is LOG, which means that failed request will only produce a log message.
            // Consider overriding it with WARNING or ERROR if failure needs to be surfaced to the user.
            localVarRequestOptions.failureSeverity = localVarRequestOptions.failureSeverity ?? RequestFailureSeverity.LOG;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Checks status of email verification session
         * @param {string} verificationSessionId Verification session ID returned from email verification request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verificationSessionsVerificationSessionIdGet: async (verificationSessionId: string, options: Partial<MPAxiosRequestConfig> = {}): Promise<RequestArgs> => {
            // verify required parameter 'verificationSessionId' is not null or undefined
            assertParamExists('verificationSessionsVerificationSessionIdGet', 'verificationSessionId', verificationSessionId)
            const localVarPath = `/verification-sessions/{verification_session_id}`
                .replace(`{${"verification_session_id"}}`, encodeURIComponent(String(verificationSessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            // Add request's nickname to AxiosRequest config to be able to know the name of the method if it failed
            localVarRequestOptions.nickname = 'verificationSessionsVerificationSessionIdGet';

            // Default failure severity is LOG, which means that failed request will only produce a log message.
            // Consider overriding it with WARNING or ERROR if failure needs to be surfaced to the user.
            localVarRequestOptions.failureSeverity = localVarRequestOptions.failureSeverity ?? RequestFailureSeverity.LOG;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BroadcastsApi - functional programming interface
 * @export
 */
export const BroadcastsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BroadcastsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates an invite for a broadcast
         * @param {string} conversationId The ID for the broadcast
         * @param {WebInviteeId} webInviteeId The Id of the person invited to the Sharecast
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async broadcastsConversationIdInvitesPost(conversationId: string, webInviteeId: WebInviteeId, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Invite>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.broadcastsConversationIdInvitesPost(conversationId, webInviteeId, options);
            return createRequestFunction<Invite, string>(localVarAxiosArgs, globalAxios, BASE_PATH, 'Invite', configuration);
        },
        /**
         * 
         * @summary Triggers phone verification
         * @param {string} conversationId The ID for the Sharecast
         * @param {string} webInviteId The ID for the Sharecast invite
         * @param {PhoneVerificationTrigger} phoneVerificationTrigger The phone number the invite should be sent to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async broadcastsConversationIdInvitesWebInviteIdSendCodePhonePost(conversationId: string, webInviteId: string, phoneVerificationTrigger: PhoneVerificationTrigger, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmptySuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.broadcastsConversationIdInvitesWebInviteIdSendCodePhonePost(conversationId, webInviteId, phoneVerificationTrigger, options);
            return createRequestFunction<EmptySuccessResponse, string>(localVarAxiosArgs, globalAxios, BASE_PATH, 'EmptySuccessResponse', configuration);
        },
        /**
         * 
         * @summary Triggers email verification
         * @param {string} conversationId The ID for the Sharecast
         * @param {string} webInviteId The ID for the Sharecast invite
         * @param {EmailVerificationTrigger} emailVerificationTrigger The required info to verify the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async broadcastsConversationIdInvitesWebInviteIdSendVerificationEmailPost(conversationId: string, webInviteId: string, emailVerificationTrigger: EmailVerificationTrigger, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailVerificationSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.broadcastsConversationIdInvitesWebInviteIdSendVerificationEmailPost(conversationId, webInviteId, emailVerificationTrigger, options);
            return createRequestFunction<EmailVerificationSuccessResponse, string>(localVarAxiosArgs, globalAxios, BASE_PATH, 'EmailVerificationSuccessResponse', configuration);
        },
        /**
         * 
         * @summary Triggers email verification
         * @param {string} conversationId The ID for the Sharecast
         * @param {string} webInviteId The ID for the Sharecast invite
         * @param {PhoneVerificationBody} phoneVerificationBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async broadcastsConversationIdInvitesWebInviteIdVerifyCodePhonePost(conversationId: string, webInviteId: string, phoneVerificationBody: PhoneVerificationBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerificationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.broadcastsConversationIdInvitesWebInviteIdVerifyCodePhonePost(conversationId, webInviteId, phoneVerificationBody, options);
            return createRequestFunction<VerificationResponse, string>(localVarAxiosArgs, globalAxios, BASE_PATH, 'VerificationResponse', configuration);
        },
        /**
         * Notifies the server that the user has watched a Sharecast message
         * @summary Reports Sharecast message as watched
         * @param {string} conversationId The ID for the Sharecast
         * @param {string} messageId The ID of the Sharecast message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async broadcastsConversationIdMessagesMessageIdWatchedPost(conversationId: string, messageId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmptySuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.broadcastsConversationIdMessagesMessageIdWatchedPost(conversationId, messageId, options);
            return createRequestFunction<EmptySuccessResponse, string>(localVarAxiosArgs, globalAxios, BASE_PATH, 'EmptySuccessResponse', configuration);
        },
        /**
         * 
         * @summary Signup for Sharecast updates
         * @param {string} conversationId The ID for the Sharecast
         * @param {SharecastSignupBody} sharecastSignupBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async broadcastsConversationIdSubscriptionsPost(conversationId: string, sharecastSignupBody: SharecastSignupBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse201>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.broadcastsConversationIdSubscriptionsPost(conversationId, sharecastSignupBody, options);
            return createRequestFunction<InlineResponse201, string>(localVarAxiosArgs, globalAxios, BASE_PATH, 'InlineResponse201', configuration);
        },
        /**
         * 
         * @summary Retrieve subscription state for user
         * @param {string} conversationId The ID for the Sharecast
         * @param {string} webUserId The user\&#39;s ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async broadcastsConversationIdSubscriptionsWebUserIdGet(conversationId: string, webUserId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionStateBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.broadcastsConversationIdSubscriptionsWebUserIdGet(conversationId, webUserId, options);
            return createRequestFunction<SubscriptionStateBody, string>(localVarAxiosArgs, globalAxios, BASE_PATH, 'SubscriptionStateBody', configuration);
        },
        /**
         * 
         * @summary Update subscription settings
         * @param {string} conversationId The ID for the Sharecast
         * @param {string} webUserId The user\&#39;s ID
         * @param {SubscriptionStateUpdatePayload} subscriptionStateUpdatePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async broadcastsConversationIdSubscriptionsWebUserIdPatch(conversationId: string, webUserId: string, subscriptionStateUpdatePayload: SubscriptionStateUpdatePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmptySuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.broadcastsConversationIdSubscriptionsWebUserIdPatch(conversationId, webUserId, subscriptionStateUpdatePayload, options);
            return createRequestFunction<EmptySuccessResponse, string>(localVarAxiosArgs, globalAxios, BASE_PATH, 'EmptySuccessResponse', configuration);
        },
        /**
         * Reports the start of Sharecast message viewing session
         * @summary Reports viewing session of Sharecast message
         * @param {string} conversationId The ID for the Sharecast
         * @param {ViewingSessionBody} viewingSessionBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async broadcastsConversationIdViewingSessionPost(conversationId: string, viewingSessionBody: ViewingSessionBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmptySuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.broadcastsConversationIdViewingSessionPost(conversationId, viewingSessionBody, options);
            return createRequestFunction<EmptySuccessResponse, string>(localVarAxiosArgs, globalAxios, BASE_PATH, 'EmptySuccessResponse', configuration);
        },
        /**
         * 
         * @summary Checks status of email verification session
         * @param {string} verificationSessionId Verification session ID returned from email verification request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verificationSessionsVerificationSessionIdGet(verificationSessionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailVerificationStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verificationSessionsVerificationSessionIdGet(verificationSessionId, options);
            return createRequestFunction<EmailVerificationStatusResponse, string>(localVarAxiosArgs, globalAxios, BASE_PATH, 'EmailVerificationStatusResponse', configuration);
        },
    }
};

/**
 * BroadcastsApi - factory interface
 * @export
 */
export const BroadcastsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BroadcastsApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates an invite for a broadcast
         * @param {string} conversationId The ID for the broadcast
         * @param {WebInviteeId} webInviteeId The Id of the person invited to the Sharecast
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        broadcastsConversationIdInvitesPost(conversationId: string, webInviteeId: WebInviteeId, options?: Partial<MPAxiosRequestConfig>): AxiosPromise<Invite> {
            return localVarFp.broadcastsConversationIdInvitesPost(conversationId, webInviteeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Triggers phone verification
         * @param {string} conversationId The ID for the Sharecast
         * @param {string} webInviteId The ID for the Sharecast invite
         * @param {PhoneVerificationTrigger} phoneVerificationTrigger The phone number the invite should be sent to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        broadcastsConversationIdInvitesWebInviteIdSendCodePhonePost(conversationId: string, webInviteId: string, phoneVerificationTrigger: PhoneVerificationTrigger, options?: Partial<MPAxiosRequestConfig>): AxiosPromise<EmptySuccessResponse> {
            return localVarFp.broadcastsConversationIdInvitesWebInviteIdSendCodePhonePost(conversationId, webInviteId, phoneVerificationTrigger, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Triggers email verification
         * @param {string} conversationId The ID for the Sharecast
         * @param {string} webInviteId The ID for the Sharecast invite
         * @param {EmailVerificationTrigger} emailVerificationTrigger The required info to verify the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        broadcastsConversationIdInvitesWebInviteIdSendVerificationEmailPost(conversationId: string, webInviteId: string, emailVerificationTrigger: EmailVerificationTrigger, options?: Partial<MPAxiosRequestConfig>): AxiosPromise<EmailVerificationSuccessResponse> {
            return localVarFp.broadcastsConversationIdInvitesWebInviteIdSendVerificationEmailPost(conversationId, webInviteId, emailVerificationTrigger, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Triggers email verification
         * @param {string} conversationId The ID for the Sharecast
         * @param {string} webInviteId The ID for the Sharecast invite
         * @param {PhoneVerificationBody} phoneVerificationBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        broadcastsConversationIdInvitesWebInviteIdVerifyCodePhonePost(conversationId: string, webInviteId: string, phoneVerificationBody: PhoneVerificationBody, options?: Partial<MPAxiosRequestConfig>): AxiosPromise<VerificationResponse> {
            return localVarFp.broadcastsConversationIdInvitesWebInviteIdVerifyCodePhonePost(conversationId, webInviteId, phoneVerificationBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Notifies the server that the user has watched a Sharecast message
         * @summary Reports Sharecast message as watched
         * @param {string} conversationId The ID for the Sharecast
         * @param {string} messageId The ID of the Sharecast message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        broadcastsConversationIdMessagesMessageIdWatchedPost(conversationId: string, messageId: string, options?: Partial<MPAxiosRequestConfig>): AxiosPromise<EmptySuccessResponse> {
            return localVarFp.broadcastsConversationIdMessagesMessageIdWatchedPost(conversationId, messageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Signup for Sharecast updates
         * @param {string} conversationId The ID for the Sharecast
         * @param {SharecastSignupBody} sharecastSignupBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        broadcastsConversationIdSubscriptionsPost(conversationId: string, sharecastSignupBody: SharecastSignupBody, options?: Partial<MPAxiosRequestConfig>): AxiosPromise<InlineResponse201> {
            return localVarFp.broadcastsConversationIdSubscriptionsPost(conversationId, sharecastSignupBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve subscription state for user
         * @param {string} conversationId The ID for the Sharecast
         * @param {string} webUserId The user\&#39;s ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        broadcastsConversationIdSubscriptionsWebUserIdGet(conversationId: string, webUserId: string, options?: Partial<MPAxiosRequestConfig>): AxiosPromise<SubscriptionStateBody> {
            return localVarFp.broadcastsConversationIdSubscriptionsWebUserIdGet(conversationId, webUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update subscription settings
         * @param {string} conversationId The ID for the Sharecast
         * @param {string} webUserId The user\&#39;s ID
         * @param {SubscriptionStateUpdatePayload} subscriptionStateUpdatePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        broadcastsConversationIdSubscriptionsWebUserIdPatch(conversationId: string, webUserId: string, subscriptionStateUpdatePayload: SubscriptionStateUpdatePayload, options?: Partial<MPAxiosRequestConfig>): AxiosPromise<EmptySuccessResponse> {
            return localVarFp.broadcastsConversationIdSubscriptionsWebUserIdPatch(conversationId, webUserId, subscriptionStateUpdatePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Reports the start of Sharecast message viewing session
         * @summary Reports viewing session of Sharecast message
         * @param {string} conversationId The ID for the Sharecast
         * @param {ViewingSessionBody} viewingSessionBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        broadcastsConversationIdViewingSessionPost(conversationId: string, viewingSessionBody: ViewingSessionBody, options?: Partial<MPAxiosRequestConfig>): AxiosPromise<EmptySuccessResponse> {
            return localVarFp.broadcastsConversationIdViewingSessionPost(conversationId, viewingSessionBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Checks status of email verification session
         * @param {string} verificationSessionId Verification session ID returned from email verification request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verificationSessionsVerificationSessionIdGet(verificationSessionId: string, options?: Partial<MPAxiosRequestConfig>): AxiosPromise<EmailVerificationStatusResponse> {
            return localVarFp.verificationSessionsVerificationSessionIdGet(verificationSessionId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for broadcastsConversationIdInvitesPost operation in BroadcastsApi.
 * @export
 * @interface BroadcastsApiBroadcastsConversationIdInvitesPostRequest
 */
export interface BroadcastsApiBroadcastsConversationIdInvitesPostRequest {
    /**
     * The ID for the broadcast
     * @type {string}
     * @memberof BroadcastsApiBroadcastsConversationIdInvitesPost
     */
    readonly conversationId: string

    /**
     * The Id of the person invited to the Sharecast
     * @type {WebInviteeId}
     * @memberof BroadcastsApiBroadcastsConversationIdInvitesPost
     */
    readonly webInviteeId: WebInviteeId
}

/**
 * Request parameters for broadcastsConversationIdInvitesWebInviteIdSendCodePhonePost operation in BroadcastsApi.
 * @export
 * @interface BroadcastsApiBroadcastsConversationIdInvitesWebInviteIdSendCodePhonePostRequest
 */
export interface BroadcastsApiBroadcastsConversationIdInvitesWebInviteIdSendCodePhonePostRequest {
    /**
     * The ID for the Sharecast
     * @type {string}
     * @memberof BroadcastsApiBroadcastsConversationIdInvitesWebInviteIdSendCodePhonePost
     */
    readonly conversationId: string

    /**
     * The ID for the Sharecast invite
     * @type {string}
     * @memberof BroadcastsApiBroadcastsConversationIdInvitesWebInviteIdSendCodePhonePost
     */
    readonly webInviteId: string

    /**
     * The phone number the invite should be sent to.
     * @type {PhoneVerificationTrigger}
     * @memberof BroadcastsApiBroadcastsConversationIdInvitesWebInviteIdSendCodePhonePost
     */
    readonly phoneVerificationTrigger: PhoneVerificationTrigger
}

/**
 * Request parameters for broadcastsConversationIdInvitesWebInviteIdSendVerificationEmailPost operation in BroadcastsApi.
 * @export
 * @interface BroadcastsApiBroadcastsConversationIdInvitesWebInviteIdSendVerificationEmailPostRequest
 */
export interface BroadcastsApiBroadcastsConversationIdInvitesWebInviteIdSendVerificationEmailPostRequest {
    /**
     * The ID for the Sharecast
     * @type {string}
     * @memberof BroadcastsApiBroadcastsConversationIdInvitesWebInviteIdSendVerificationEmailPost
     */
    readonly conversationId: string

    /**
     * The ID for the Sharecast invite
     * @type {string}
     * @memberof BroadcastsApiBroadcastsConversationIdInvitesWebInviteIdSendVerificationEmailPost
     */
    readonly webInviteId: string

    /**
     * The required info to verify the user
     * @type {EmailVerificationTrigger}
     * @memberof BroadcastsApiBroadcastsConversationIdInvitesWebInviteIdSendVerificationEmailPost
     */
    readonly emailVerificationTrigger: EmailVerificationTrigger
}

/**
 * Request parameters for broadcastsConversationIdInvitesWebInviteIdVerifyCodePhonePost operation in BroadcastsApi.
 * @export
 * @interface BroadcastsApiBroadcastsConversationIdInvitesWebInviteIdVerifyCodePhonePostRequest
 */
export interface BroadcastsApiBroadcastsConversationIdInvitesWebInviteIdVerifyCodePhonePostRequest {
    /**
     * The ID for the Sharecast
     * @type {string}
     * @memberof BroadcastsApiBroadcastsConversationIdInvitesWebInviteIdVerifyCodePhonePost
     */
    readonly conversationId: string

    /**
     * The ID for the Sharecast invite
     * @type {string}
     * @memberof BroadcastsApiBroadcastsConversationIdInvitesWebInviteIdVerifyCodePhonePost
     */
    readonly webInviteId: string

    /**
     * 
     * @type {PhoneVerificationBody}
     * @memberof BroadcastsApiBroadcastsConversationIdInvitesWebInviteIdVerifyCodePhonePost
     */
    readonly phoneVerificationBody: PhoneVerificationBody
}

/**
 * Request parameters for broadcastsConversationIdMessagesMessageIdWatchedPost operation in BroadcastsApi.
 * @export
 * @interface BroadcastsApiBroadcastsConversationIdMessagesMessageIdWatchedPostRequest
 */
export interface BroadcastsApiBroadcastsConversationIdMessagesMessageIdWatchedPostRequest {
    /**
     * The ID for the Sharecast
     * @type {string}
     * @memberof BroadcastsApiBroadcastsConversationIdMessagesMessageIdWatchedPost
     */
    readonly conversationId: string

    /**
     * The ID of the Sharecast message
     * @type {string}
     * @memberof BroadcastsApiBroadcastsConversationIdMessagesMessageIdWatchedPost
     */
    readonly messageId: string
}

/**
 * Request parameters for broadcastsConversationIdSubscriptionsPost operation in BroadcastsApi.
 * @export
 * @interface BroadcastsApiBroadcastsConversationIdSubscriptionsPostRequest
 */
export interface BroadcastsApiBroadcastsConversationIdSubscriptionsPostRequest {
    /**
     * The ID for the Sharecast
     * @type {string}
     * @memberof BroadcastsApiBroadcastsConversationIdSubscriptionsPost
     */
    readonly conversationId: string

    /**
     * 
     * @type {SharecastSignupBody}
     * @memberof BroadcastsApiBroadcastsConversationIdSubscriptionsPost
     */
    readonly sharecastSignupBody: SharecastSignupBody
}

/**
 * Request parameters for broadcastsConversationIdSubscriptionsWebUserIdGet operation in BroadcastsApi.
 * @export
 * @interface BroadcastsApiBroadcastsConversationIdSubscriptionsWebUserIdGetRequest
 */
export interface BroadcastsApiBroadcastsConversationIdSubscriptionsWebUserIdGetRequest {
    /**
     * The ID for the Sharecast
     * @type {string}
     * @memberof BroadcastsApiBroadcastsConversationIdSubscriptionsWebUserIdGet
     */
    readonly conversationId: string

    /**
     * The user\&#39;s ID
     * @type {string}
     * @memberof BroadcastsApiBroadcastsConversationIdSubscriptionsWebUserIdGet
     */
    readonly webUserId: string
}

/**
 * Request parameters for broadcastsConversationIdSubscriptionsWebUserIdPatch operation in BroadcastsApi.
 * @export
 * @interface BroadcastsApiBroadcastsConversationIdSubscriptionsWebUserIdPatchRequest
 */
export interface BroadcastsApiBroadcastsConversationIdSubscriptionsWebUserIdPatchRequest {
    /**
     * The ID for the Sharecast
     * @type {string}
     * @memberof BroadcastsApiBroadcastsConversationIdSubscriptionsWebUserIdPatch
     */
    readonly conversationId: string

    /**
     * The user\&#39;s ID
     * @type {string}
     * @memberof BroadcastsApiBroadcastsConversationIdSubscriptionsWebUserIdPatch
     */
    readonly webUserId: string

    /**
     * 
     * @type {SubscriptionStateUpdatePayload}
     * @memberof BroadcastsApiBroadcastsConversationIdSubscriptionsWebUserIdPatch
     */
    readonly subscriptionStateUpdatePayload: SubscriptionStateUpdatePayload
}

/**
 * Request parameters for broadcastsConversationIdViewingSessionPost operation in BroadcastsApi.
 * @export
 * @interface BroadcastsApiBroadcastsConversationIdViewingSessionPostRequest
 */
export interface BroadcastsApiBroadcastsConversationIdViewingSessionPostRequest {
    /**
     * The ID for the Sharecast
     * @type {string}
     * @memberof BroadcastsApiBroadcastsConversationIdViewingSessionPost
     */
    readonly conversationId: string

    /**
     * 
     * @type {ViewingSessionBody}
     * @memberof BroadcastsApiBroadcastsConversationIdViewingSessionPost
     */
    readonly viewingSessionBody: ViewingSessionBody
}

/**
 * Request parameters for verificationSessionsVerificationSessionIdGet operation in BroadcastsApi.
 * @export
 * @interface BroadcastsApiVerificationSessionsVerificationSessionIdGetRequest
 */
export interface BroadcastsApiVerificationSessionsVerificationSessionIdGetRequest {
    /**
     * Verification session ID returned from email verification request
     * @type {string}
     * @memberof BroadcastsApiVerificationSessionsVerificationSessionIdGet
     */
    readonly verificationSessionId: string
}

/**
 * BroadcastsApi - object-oriented interface
 * @export
 * @class BroadcastsApi
 * @extends {BaseAPI}
 */
export class BroadcastsApi extends BaseAPI {
    /**
     * 
     * @summary Creates an invite for a broadcast
     * @param {BroadcastsApiBroadcastsConversationIdInvitesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof BroadcastsApi
     */
    public broadcastsConversationIdInvitesPost(requestParameters: BroadcastsApiBroadcastsConversationIdInvitesPostRequest, options?: AxiosRequestConfig) {
        return BroadcastsApiFp(this.configuration).broadcastsConversationIdInvitesPost(requestParameters.conversationId, requestParameters.webInviteeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Triggers phone verification
     * @param {BroadcastsApiBroadcastsConversationIdInvitesWebInviteIdSendCodePhonePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BroadcastsApi
     */
    public broadcastsConversationIdInvitesWebInviteIdSendCodePhonePost(requestParameters: BroadcastsApiBroadcastsConversationIdInvitesWebInviteIdSendCodePhonePostRequest, options?: AxiosRequestConfig) {
        return BroadcastsApiFp(this.configuration).broadcastsConversationIdInvitesWebInviteIdSendCodePhonePost(requestParameters.conversationId, requestParameters.webInviteId, requestParameters.phoneVerificationTrigger, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Triggers email verification
     * @param {BroadcastsApiBroadcastsConversationIdInvitesWebInviteIdSendVerificationEmailPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BroadcastsApi
     */
    public broadcastsConversationIdInvitesWebInviteIdSendVerificationEmailPost(requestParameters: BroadcastsApiBroadcastsConversationIdInvitesWebInviteIdSendVerificationEmailPostRequest, options?: AxiosRequestConfig) {
        return BroadcastsApiFp(this.configuration).broadcastsConversationIdInvitesWebInviteIdSendVerificationEmailPost(requestParameters.conversationId, requestParameters.webInviteId, requestParameters.emailVerificationTrigger, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Triggers email verification
     * @param {BroadcastsApiBroadcastsConversationIdInvitesWebInviteIdVerifyCodePhonePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BroadcastsApi
     */
    public broadcastsConversationIdInvitesWebInviteIdVerifyCodePhonePost(requestParameters: BroadcastsApiBroadcastsConversationIdInvitesWebInviteIdVerifyCodePhonePostRequest, options?: AxiosRequestConfig) {
        return BroadcastsApiFp(this.configuration).broadcastsConversationIdInvitesWebInviteIdVerifyCodePhonePost(requestParameters.conversationId, requestParameters.webInviteId, requestParameters.phoneVerificationBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Notifies the server that the user has watched a Sharecast message
     * @summary Reports Sharecast message as watched
     * @param {BroadcastsApiBroadcastsConversationIdMessagesMessageIdWatchedPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BroadcastsApi
     */
    public broadcastsConversationIdMessagesMessageIdWatchedPost(requestParameters: BroadcastsApiBroadcastsConversationIdMessagesMessageIdWatchedPostRequest, options?: AxiosRequestConfig) {
        return BroadcastsApiFp(this.configuration).broadcastsConversationIdMessagesMessageIdWatchedPost(requestParameters.conversationId, requestParameters.messageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Signup for Sharecast updates
     * @param {BroadcastsApiBroadcastsConversationIdSubscriptionsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BroadcastsApi
     */
    public broadcastsConversationIdSubscriptionsPost(requestParameters: BroadcastsApiBroadcastsConversationIdSubscriptionsPostRequest, options?: AxiosRequestConfig) {
        return BroadcastsApiFp(this.configuration).broadcastsConversationIdSubscriptionsPost(requestParameters.conversationId, requestParameters.sharecastSignupBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve subscription state for user
     * @param {BroadcastsApiBroadcastsConversationIdSubscriptionsWebUserIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BroadcastsApi
     */
    public broadcastsConversationIdSubscriptionsWebUserIdGet(requestParameters: BroadcastsApiBroadcastsConversationIdSubscriptionsWebUserIdGetRequest, options?: AxiosRequestConfig) {
        return BroadcastsApiFp(this.configuration).broadcastsConversationIdSubscriptionsWebUserIdGet(requestParameters.conversationId, requestParameters.webUserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update subscription settings
     * @param {BroadcastsApiBroadcastsConversationIdSubscriptionsWebUserIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BroadcastsApi
     */
    public broadcastsConversationIdSubscriptionsWebUserIdPatch(requestParameters: BroadcastsApiBroadcastsConversationIdSubscriptionsWebUserIdPatchRequest, options?: AxiosRequestConfig) {
        return BroadcastsApiFp(this.configuration).broadcastsConversationIdSubscriptionsWebUserIdPatch(requestParameters.conversationId, requestParameters.webUserId, requestParameters.subscriptionStateUpdatePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Reports the start of Sharecast message viewing session
     * @summary Reports viewing session of Sharecast message
     * @param {BroadcastsApiBroadcastsConversationIdViewingSessionPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BroadcastsApi
     */
    public broadcastsConversationIdViewingSessionPost(requestParameters: BroadcastsApiBroadcastsConversationIdViewingSessionPostRequest, options?: AxiosRequestConfig) {
        return BroadcastsApiFp(this.configuration).broadcastsConversationIdViewingSessionPost(requestParameters.conversationId, requestParameters.viewingSessionBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Checks status of email verification session
     * @param {BroadcastsApiVerificationSessionsVerificationSessionIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BroadcastsApi
     */
    public verificationSessionsVerificationSessionIdGet(requestParameters: BroadcastsApiVerificationSessionsVerificationSessionIdGetRequest, options?: AxiosRequestConfig) {
        return BroadcastsApiFp(this.configuration).verificationSessionsVerificationSessionIdGet(requestParameters.verificationSessionId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EventsApi - axios parameter creator
 * @export
 */
export const EventsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Handles analytics events
         * @param {EventBody} eventBody The analytics event
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventsPost: async (eventBody: EventBody, options: Partial<MPAxiosRequestConfig> = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventBody' is not null or undefined
            assertParamExists('eventsPost', 'eventBody', eventBody)
            const localVarPath = `/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eventBody, localVarRequestOptions, configuration)

            // Add request's nickname to AxiosRequest config to be able to know the name of the method if it failed
            localVarRequestOptions.nickname = 'eventsPost';

            // Default failure severity is LOG, which means that failed request will only produce a log message.
            // Consider overriding it with WARNING or ERROR if failure needs to be surfaced to the user.
            localVarRequestOptions.failureSeverity = localVarRequestOptions.failureSeverity ?? RequestFailureSeverity.LOG;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventsApi - functional programming interface
 * @export
 */
export const EventsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Handles analytics events
         * @param {EventBody} eventBody The analytics event
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eventsPost(eventBody: EventBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmptySuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eventsPost(eventBody, options);
            return createRequestFunction<EmptySuccessResponse, EventBody>(localVarAxiosArgs, globalAxios, BASE_PATH, 'EmptySuccessResponse', configuration);
        },
    }
};

/**
 * EventsApi - factory interface
 * @export
 */
export const EventsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventsApiFp(configuration)
    return {
        /**
         * 
         * @summary Handles analytics events
         * @param {EventBody} eventBody The analytics event
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventsPost(eventBody: EventBody, options?: Partial<MPAxiosRequestConfig>): AxiosPromise<EmptySuccessResponse> {
            return localVarFp.eventsPost(eventBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for eventsPost operation in EventsApi.
 * @export
 * @interface EventsApiEventsPostRequest
 */
export interface EventsApiEventsPostRequest {
    /**
     * The analytics event
     * @type {EventBody}
     * @memberof EventsApiEventsPost
     */
    readonly eventBody: EventBody
}

/**
 * EventsApi - object-oriented interface
 * @export
 * @class EventsApi
 * @extends {BaseAPI}
 */
export class EventsApi extends BaseAPI {
    /**
     * 
     * @summary Handles analytics events
     * @param {EventsApiEventsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public eventsPost(requestParameters: EventsApiEventsPostRequest, options?: AxiosRequestConfig) {
        return EventsApiFp(this.configuration).eventsPost(requestParameters.eventBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PlusPassApi - axios parameter creator
 * @export
 */
export const PlusPassApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieves information about partner plus pass giveaways
         * @param {string} partnerName The name of the partner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionsGivePlusPartnerNameGet: async (partnerName: string, options: Partial<MPAxiosRequestConfig> = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerName' is not null or undefined
            assertParamExists('subscriptionsGivePlusPartnerNameGet', 'partnerName', partnerName)
            const localVarPath = `/subscriptions/give-plus/{partner_name}`
                .replace(`{${"partner_name"}}`, encodeURIComponent(String(partnerName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            // Add request's nickname to AxiosRequest config to be able to know the name of the method if it failed
            localVarRequestOptions.nickname = 'subscriptionsGivePlusPartnerNameGet';

            // Default failure severity is LOG, which means that failed request will only produce a log message.
            // Consider overriding it with WARNING or ERROR if failure needs to be surfaced to the user.
            localVarRequestOptions.failureSeverity = localVarRequestOptions.failureSeverity ?? RequestFailureSeverity.LOG;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Submit user phone number for plus pass giveaway
         * @param {string} partnerName The name of the partner
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionsGivePlusPartnerNamePost: async (partnerName: string, inlineObject?: InlineObject, options: Partial<MPAxiosRequestConfig> = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerName' is not null or undefined
            assertParamExists('subscriptionsGivePlusPartnerNamePost', 'partnerName', partnerName)
            const localVarPath = `/subscriptions/give-plus/{partner_name}`
                .replace(`{${"partner_name"}}`, encodeURIComponent(String(partnerName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject, localVarRequestOptions, configuration)

            // Add request's nickname to AxiosRequest config to be able to know the name of the method if it failed
            localVarRequestOptions.nickname = 'subscriptionsGivePlusPartnerNamePost';

            // Default failure severity is LOG, which means that failed request will only produce a log message.
            // Consider overriding it with WARNING or ERROR if failure needs to be surfaced to the user.
            localVarRequestOptions.failureSeverity = localVarRequestOptions.failureSeverity ?? RequestFailureSeverity.LOG;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlusPassApi - functional programming interface
 * @export
 */
export const PlusPassApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlusPassApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieves information about partner plus pass giveaways
         * @param {string} partnerName The name of the partner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionsGivePlusPartnerNameGet(partnerName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlusPassPartnerInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscriptionsGivePlusPartnerNameGet(partnerName, options);
            return createRequestFunction<PlusPassPartnerInfo, string>(localVarAxiosArgs, globalAxios, BASE_PATH, 'PlusPassPartnerInfo', configuration);
        },
        /**
         * 
         * @summary Submit user phone number for plus pass giveaway
         * @param {string} partnerName The name of the partner
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionsGivePlusPartnerNamePost(partnerName: string, inlineObject?: InlineObject, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse201>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscriptionsGivePlusPartnerNamePost(partnerName, inlineObject, options);
            return createRequestFunction<InlineResponse201, string>(localVarAxiosArgs, globalAxios, BASE_PATH, 'InlineResponse201', configuration);
        },
    }
};

/**
 * PlusPassApi - factory interface
 * @export
 */
export const PlusPassApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlusPassApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieves information about partner plus pass giveaways
         * @param {string} partnerName The name of the partner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionsGivePlusPartnerNameGet(partnerName: string, options?: Partial<MPAxiosRequestConfig>): AxiosPromise<PlusPassPartnerInfo> {
            return localVarFp.subscriptionsGivePlusPartnerNameGet(partnerName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Submit user phone number for plus pass giveaway
         * @param {string} partnerName The name of the partner
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionsGivePlusPartnerNamePost(partnerName: string, inlineObject?: InlineObject, options?: Partial<MPAxiosRequestConfig>): AxiosPromise<InlineResponse201> {
            return localVarFp.subscriptionsGivePlusPartnerNamePost(partnerName, inlineObject, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for subscriptionsGivePlusPartnerNameGet operation in PlusPassApi.
 * @export
 * @interface PlusPassApiSubscriptionsGivePlusPartnerNameGetRequest
 */
export interface PlusPassApiSubscriptionsGivePlusPartnerNameGetRequest {
    /**
     * The name of the partner
     * @type {string}
     * @memberof PlusPassApiSubscriptionsGivePlusPartnerNameGet
     */
    readonly partnerName: string
}

/**
 * Request parameters for subscriptionsGivePlusPartnerNamePost operation in PlusPassApi.
 * @export
 * @interface PlusPassApiSubscriptionsGivePlusPartnerNamePostRequest
 */
export interface PlusPassApiSubscriptionsGivePlusPartnerNamePostRequest {
    /**
     * The name of the partner
     * @type {string}
     * @memberof PlusPassApiSubscriptionsGivePlusPartnerNamePost
     */
    readonly partnerName: string

    /**
     * 
     * @type {InlineObject}
     * @memberof PlusPassApiSubscriptionsGivePlusPartnerNamePost
     */
    readonly inlineObject?: InlineObject
}

/**
 * PlusPassApi - object-oriented interface
 * @export
 * @class PlusPassApi
 * @extends {BaseAPI}
 */
export class PlusPassApi extends BaseAPI {
    /**
     * 
     * @summary Retrieves information about partner plus pass giveaways
     * @param {PlusPassApiSubscriptionsGivePlusPartnerNameGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlusPassApi
     */
    public subscriptionsGivePlusPartnerNameGet(requestParameters: PlusPassApiSubscriptionsGivePlusPartnerNameGetRequest, options?: AxiosRequestConfig) {
        return PlusPassApiFp(this.configuration).subscriptionsGivePlusPartnerNameGet(requestParameters.partnerName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Submit user phone number for plus pass giveaway
     * @param {PlusPassApiSubscriptionsGivePlusPartnerNamePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlusPassApi
     */
    public subscriptionsGivePlusPartnerNamePost(requestParameters: PlusPassApiSubscriptionsGivePlusPartnerNamePostRequest, options?: AxiosRequestConfig) {
        return PlusPassApiFp(this.configuration).subscriptionsGivePlusPartnerNamePost(requestParameters.partnerName, requestParameters.inlineObject, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProApi - axios parameter creator
 * @export
 */
export const ProApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary retrieves message information for web playback
         * @param {string} messageCode The code identifying the message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proLinksMessagesMessageCodePost: async (messageCode: string, options: Partial<MPAxiosRequestConfig> = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageCode' is not null or undefined
            assertParamExists('proLinksMessagesMessageCodePost', 'messageCode', messageCode)
            const localVarPath = `/pro-links/messages/{message_code}`
                .replace(`{${"message_code"}}`, encodeURIComponent(String(messageCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            // Add request's nickname to AxiosRequest config to be able to know the name of the method if it failed
            localVarRequestOptions.nickname = 'proLinksMessagesMessageCodePost';

            // Default failure severity is LOG, which means that failed request will only produce a log message.
            // Consider overriding it with WARNING or ERROR if failure needs to be surfaced to the user.
            localVarRequestOptions.failureSeverity = localVarRequestOptions.failureSeverity ?? RequestFailureSeverity.LOG;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProApi - functional programming interface
 * @export
 */
export const ProApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary retrieves message information for web playback
         * @param {string} messageCode The code identifying the message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proLinksMessagesMessageCodePost(messageCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProMessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proLinksMessagesMessageCodePost(messageCode, options);
            return createRequestFunction<ProMessageResponse, string>(localVarAxiosArgs, globalAxios, BASE_PATH, 'ProMessageResponse', configuration);
        },
    }
};

/**
 * ProApi - factory interface
 * @export
 */
export const ProApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProApiFp(configuration)
    return {
        /**
         * 
         * @summary retrieves message information for web playback
         * @param {string} messageCode The code identifying the message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proLinksMessagesMessageCodePost(messageCode: string, options?: Partial<MPAxiosRequestConfig>): AxiosPromise<ProMessageResponse> {
            return localVarFp.proLinksMessagesMessageCodePost(messageCode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for proLinksMessagesMessageCodePost operation in ProApi.
 * @export
 * @interface ProApiProLinksMessagesMessageCodePostRequest
 */
export interface ProApiProLinksMessagesMessageCodePostRequest {
    /**
     * The code identifying the message
     * @type {string}
     * @memberof ProApiProLinksMessagesMessageCodePost
     */
    readonly messageCode: string
}

/**
 * ProApi - object-oriented interface
 * @export
 * @class ProApi
 * @extends {BaseAPI}
 */
export class ProApi extends BaseAPI {
    /**
     * 
     * @summary retrieves message information for web playback
     * @param {ProApiProLinksMessagesMessageCodePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProApi
     */
    public proLinksMessagesMessageCodePost(requestParameters: ProApiProLinksMessagesMessageCodePostRequest, options?: AxiosRequestConfig) {
        return ProApiFp(this.configuration).proLinksMessagesMessageCodePost(requestParameters.messageCode, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SecondsApi - axios parameter creator
 * @export
 */
export const SecondsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets the most recent information about a seconds share token
         * @param {string} shareToken Share token for the seconds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        secondsSharesShareTokenGet: async (shareToken: string, options: Partial<MPAxiosRequestConfig> = {}): Promise<RequestArgs> => {
            // verify required parameter 'shareToken' is not null or undefined
            assertParamExists('secondsSharesShareTokenGet', 'shareToken', shareToken)
            const localVarPath = `/seconds/shares/{share_token}`
                .replace(`{${"share_token"}}`, encodeURIComponent(String(shareToken)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            // Add request's nickname to AxiosRequest config to be able to know the name of the method if it failed
            localVarRequestOptions.nickname = 'secondsSharesShareTokenGet';

            // Default failure severity is LOG, which means that failed request will only produce a log message.
            // Consider overriding it with WARNING or ERROR if failure needs to be surfaced to the user.
            localVarRequestOptions.failureSeverity = localVarRequestOptions.failureSeverity ?? RequestFailureSeverity.LOG;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SecondsApi - functional programming interface
 * @export
 */
export const SecondsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SecondsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets the most recent information about a seconds share token
         * @param {string} shareToken Share token for the seconds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async secondsSharesShareTokenGet(shareToken: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecondsShareInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.secondsSharesShareTokenGet(shareToken, options);
            return createRequestFunction<SecondsShareInfo, string>(localVarAxiosArgs, globalAxios, BASE_PATH, 'SecondsShareInfo', configuration);
        },
    }
};

/**
 * SecondsApi - factory interface
 * @export
 */
export const SecondsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SecondsApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets the most recent information about a seconds share token
         * @param {string} shareToken Share token for the seconds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        secondsSharesShareTokenGet(shareToken: string, options?: Partial<MPAxiosRequestConfig>): AxiosPromise<SecondsShareInfo> {
            return localVarFp.secondsSharesShareTokenGet(shareToken, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for secondsSharesShareTokenGet operation in SecondsApi.
 * @export
 * @interface SecondsApiSecondsSharesShareTokenGetRequest
 */
export interface SecondsApiSecondsSharesShareTokenGetRequest {
    /**
     * Share token for the seconds
     * @type {string}
     * @memberof SecondsApiSecondsSharesShareTokenGet
     */
    readonly shareToken: string
}

/**
 * SecondsApi - object-oriented interface
 * @export
 * @class SecondsApi
 * @extends {BaseAPI}
 */
export class SecondsApi extends BaseAPI {
    /**
     * 
     * @summary Gets the most recent information about a seconds share token
     * @param {SecondsApiSecondsSharesShareTokenGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecondsApi
     */
    public secondsSharesShareTokenGet(requestParameters: SecondsApiSecondsSharesShareTokenGetRequest, options?: AxiosRequestConfig) {
        return SecondsApiFp(this.configuration).secondsSharesShareTokenGet(requestParameters.shareToken, options).then((request) => request(this.axios, this.basePath));
    }
}



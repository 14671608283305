import 'primevue/resources/themes/tailwind-light/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
// Initialize the current environment early by importing the import meta environment
import './import-meta-environment';

import { createRouter, createWebHistory } from 'vue-router';

import App from '~/App.vue';
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import { createApp } from 'vue';
import routes from '~/routes';
import tracing from '~/plugins/tracing';
import analytics from '~/plugins/analytics';

const router = createRouter({
  history: createWebHistory(),
  routes
});

const app = createApp(App);

app.use(tracing, { router });
app.use(analytics);
app.use(PrimeVue);
app.use(ToastService);
app.use(router);
app.mount('#app');

import * as Vue from 'vue';

import {
  BroadcastInviteProperties,
  BroadcastMessageProperties,
  BroadcastWebErrorPropertiesErrorSeverityEnum,
  EventBody,
  SecondsWebError,
  SecondsWebErrorAllOfPropertiesErrorSeverityEnum,
  ProShareWebError,
  BroadcastWebErrorPropertiesAllOfErrorSeverityEnum
} from '~/apis/generated';
import { Common as CommonApi, EventDetails } from '~/apis/common';
import { MPAxiosRequestConfig, RequestFailureSeverity } from '~/apis/generated-helpers';
import sentry, { BreadcrumbCategory } from '~/services/sentry';

import { EnvironmentType } from '~/environment';
import { EventEmitter } from 'eventemitter3';
import { SecondsShareContext } from '~/models/seconds';
import { ToastSeverity } from 'primevue/api';
import axios from 'axios';
import { useEnsured as useEnvironmentEnsured } from '~/composables/use-environment';
import { ProMessageShareContext } from '~/models/pro';

type TeardownFunction = () => void;

/**
 * Flags, based on debug query parameters
 */
enum DebugQueryParam {
  // Shows toast every time analytics event gets sent
  Analytics = 'debug_analytics'
}

type ErrorSeverity = 'warn' | 'error';
type ErrorEventProperties =
  | BroadcastInviteProperties
  | BroadcastMessageProperties
  | SecondsShareContext
  | ProMessageShareContext;

export enum ServiceEvent {
  Error = 'error',
  Warning = 'warn',
  Event = 'event'
}

export declare interface ServiceEventListeners {
  [ServiceEvent.Error]: (data: Error) => void;
  [ServiceEvent.Warning]: (data: string) => void;
  [ServiceEvent.Event]: (data: EventDetails<EventBody>) => void;
}

interface SendErrorEventCallArguments {
  error: unknown;
  info: string;
  severity: ErrorSeverity;
}

class TracingService {
  private _commonApi: CommonApi | undefined;
  private _eventContext: ErrorEventProperties | undefined;
  private _emitter = new EventEmitter();
  private _environment = useEnvironmentEnsured();
  // Track whether we're attempting to report an error so we don't report errors with error reporting - because infinite
  // loops are bad.
  private _isReportingError = false;

  private reportWebErrorCallQueue: SendErrorEventCallArguments[] = [];

  onEvent(callback: (data: EventDetails<EventBody>) => void): TeardownFunction {
    function listener(data: EventDetails<EventBody>) {
      callback(data);
    }

    this.on(ServiceEvent.Event, listener);

    return () => this.off(ServiceEvent.Event, listener);
  }

  onError(callback: (data: Error) => void): TeardownFunction {
    function listener(data: Error) {
      callback(data);
    }

    this.on(ServiceEvent.Error, listener);

    return () => this.off(ServiceEvent.Error, listener);
  }

  // The service itself is an event emitter
  private on<E extends keyof ServiceEventListeners, Context = undefined>(
    event: E,
    listener: ServiceEventListeners[E],
    context?: Context
  ): void {
    this._emitter.on(event, listener, context);
  }

  private off<E extends keyof ServiceEventListeners, Context = undefined>(
    event: E,
    listener?: ServiceEventListeners[E],
    context?: Context,
    once?: boolean
  ): void {
    this._emitter.off(event, listener, context, once);
  }

  private emit<E extends keyof ServiceEventListeners>(
    event: E,
    payload: Parameters<ServiceEventListeners[E]>[0]
  ): boolean {
    return this._emitter.emit(event, payload);
  }

  /**
   * Installs global error handler
   *
   * @param app - The Vue instance of the app
   */
  install(app: Vue.App<Element>) {
    app.config.errorHandler = this.handleUncaughtError.bind(this);

    window.addEventListener('unhandledrejection', event => {
      this.handleUncaughtError(event.reason, null, `Unhandled promise rejection: ${event.promise}`);
    });

    window.addEventListener('error', event => {
      this.handleUncaughtError(event.error, null, `Unhandled error caught: ${event.message}`);
    });
  }

  /**
   * Sets the context for future web error dispatches
   *
   * @param commonApi - The Common API instance
   * @param eventContext - Error event properties
   */
  async setWebErrorContext(commonApi: CommonApi, eventContext: ErrorEventProperties) {
    this._commonApi = commonApi;
    this._eventContext = eventContext;

    /**
     * There might be previous calls queued, so we try to send them now.
     */
    while (this.reportWebErrorCallQueue.length > 0) {
      const args = this.reportWebErrorCallQueue.shift() as SendErrorEventCallArguments;

      // To avoid infinite looping we try to send the error again, but only once.
      try {
        await this.reportWebError(args.error, args.info, args.severity);
      } catch (error) {
        sentry.warn(`Could not send queued error event: ${JSON.stringify(args, null, 2)}`);
      }
    }
  }

  /**
   * Global error handler
   *
   * @param error - Unhandled error
   * @param vue - Vue App instance
   * @param info - Additional information about the error
   */
  private handleUncaughtError(
    error: unknown,
    vue: Vue.ComponentPublicInstance | null,
    info: string
  ): void {
    const context: Record<string, string> = { info };

    // Special handling for uncaught errors from API requests
    if (axios.isAxiosError(error)) {
      context.nickname = (error.config as MPAxiosRequestConfig).nickname ?? 'unknownRequest';
      const failureSeverity =
        (error.config as MPAxiosRequestConfig).failureSeverity ?? RequestFailureSeverity.LOG;

      if (failureSeverity !== RequestFailureSeverity.ERROR) {
        // At this point we know that it was an error from API request, but it wasn't severe enough to
        // interrupt the user flow. We're explicitly ignoring it and showing a warning to the user.
        vue?.$toast.add({
          severity: ToastSeverity.WARN,
          summary: 'Something went wrong',
          detail: 'If problems continue try reloading the page or contact support'
        });

        sentry.warn(error.message, context);
        this.reportWebError(error, info, BroadcastWebErrorPropertiesErrorSeverityEnum.Warn);
        return;
      }
    }

    // Process the error the regular way
    this.error(error, context);

    // If we have reached this point and we're not in debug mode — the error is fatal and we're redirecting to error page
    if (!this.showErrors) {
      vue?.$router.push({
        name: 'error',
        params: { pathMatch: vue?.$route.path.substring(1).split('/') }
      });
    }
  }

  /**
   * Reports an error to Analytics
   *
   * @param error The error to report
   * @param info Additional information about the error
   * @param severity Severity level
   * @returns
   */
  private async reportWebError(
    error: SendErrorEventCallArguments['error'],
    info: SendErrorEventCallArguments['info'],
    severity: SendErrorEventCallArguments['severity']
  ) {
    // Skip error reporting when already reporting an error, otherwise error reporting errors can cause infinite loops
    if (!this._isReportingError) {
      this._isReportingError = true;

      try {
        /**
         * It is possible that error was handled before the API or any context for the event is ready,
         * so in order to send a meaningful analytics event we save the call in a queue and try to send it when we're ready.
         */
        if (!this._commonApi || !this._eventContext) {
          sentry.warn('Error occured before context was set. Queueing call for future attempt.');
          this.reportWebErrorCallQueue.push({ error, info, severity });
          return;
        }

        if (!('creatorId' in this._eventContext)) {
          // The typing here is a little squishy due to how the generated code deals with the different property sets
          // between invite and message contexts.
          await this._commonApi.sendEvent({
            event_name: 'BCAST WEB ERROR',
            projects: ['Activation'],
            properties: {
              Message: error instanceof Object ? error.toString() : `${error}`,
              Detail: info,
              // @ts-expect-error - There's an issue with using the generated types here. We need to revisit this.
              ErrorSeverity:
                severity === 'warn'
                  ? BroadcastWebErrorPropertiesErrorSeverityEnum.Warn
                  : BroadcastWebErrorPropertiesErrorSeverityEnum.Error,
              ...this._eventContext
            }
          });
        } else if (!('messageToken' in this._eventContext)) {
          await this._commonApi.sendEvent<ProShareWebError>({
            event_name: 'PRO SHARE WEB ERROR',
            projects: ['Activation'],
            properties: {
              Message: error instanceof Object ? error.toString() : `${error}`,
              Detail: info,
              ErrorSeverity:
                severity === 'warn'
                  ? BroadcastWebErrorPropertiesAllOfErrorSeverityEnum.Warn
                  : BroadcastWebErrorPropertiesAllOfErrorSeverityEnum.Error
            }
          });
        } else {
          const { creatorId, id } = this._eventContext as SecondsShareContext;

          await this._commonApi.sendEvent<SecondsWebError>({
            event_name: 'SEC WEB ERROR',
            projects: ['Activation'],
            properties: {
              Message: error instanceof Object ? error.toString() : `${error}`,
              Detail: info,
              ErrorSeverity:
                severity === 'warn'
                  ? SecondsWebErrorAllOfPropertiesErrorSeverityEnum.Warn
                  : SecondsWebErrorAllOfPropertiesErrorSeverityEnum.Error,
              PublisherId: creatorId,
              ShareId: id
            }
          });
        }
      } catch {
        // Ignore this error since attempting to report it would likely lead to more errors
      } finally {
        this._isReportingError = false;
      }
    }
  }

  /**
   * Analytics debug mode flag
   */
  get showEvents(): boolean {
    return (
      this._environment.type !== EnvironmentType.Prod &&
      document.location.search.toLowerCase().includes(DebugQueryParam.Analytics)
    );
  }

  /**
   * Errors/warnings debug mode flag
   */
  get showErrors(): boolean {
    return this._environment.type !== EnvironmentType.Prod;
  }

  /**
   * Process the warning and report it to Sentry and Analytics
   *
   * @param message - The error message
   * @param context - Additional information about the error
   */
  warn(message: string, context: Record<string, unknown> = {}): void {
    console.warn(message, context);

    sentry.warn(message, context);

    this.emit(ServiceEvent.Warning, message);

    this.reportWebError(
      message,
      'handled warning',
      BroadcastWebErrorPropertiesErrorSeverityEnum.Warn
    );
  }

  /**
   * Process the Error and report it to Sentry and Analytics
   *
   * @param error - The error
   * @param context - Additional information about the error
   */
  error(error: unknown | Error, context: Record<string, unknown> = {}): void {
    let errToLog: Error;

    if (error instanceof Error) {
      errToLog = error;
    } else {
      errToLog = new Error(
        `Unknown error ${error} sent to tracing, check context for further details`
      );
      context['errorBody'] = error;
    }

    console.error(errToLog, context);

    sentry.error(errToLog, context);

    this.emit(ServiceEvent.Error, errToLog);

    this.reportWebError(
      errToLog.message,
      'handled error',
      BroadcastWebErrorPropertiesErrorSeverityEnum.Error
    );
  }

  /**
   * Log dispatched analytics event for debugging purposes
   */
  logEvent(event: EventDetails<EventBody>) {
    console.groupCollapsed(`[event]: ${event.event_name}`);
    console.log(JSON.stringify(event, null, 2));
    console.groupEnd();

    sentry.addBreadcrumb(BreadcrumbCategory.Analytics, event.event_name);

    this.emit(ServiceEvent.Event, event);
  }

  /**
   * Set context for future errors and warnings
   */
  setContext(name: string, context: Record<string, unknown> | null = {}): void {
    console.groupCollapsed(`[context]: ${name}`);
    console.log(JSON.stringify(context, null, 2));
    console.groupEnd();

    sentry.setContext(name, context);
  }
}

export default new TracingService();

<template>
  <router-view />

  <Toast position="top-center" :breakpoints="toastBreakpoints" />
  <Toast position="bottom-right" group="tracing" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Toast from 'primevue/toast';
import { useToast } from 'primevue/usetoast';

import { BrandColors, BrandColorsInjectionKey } from '~/models/common';
import tracing from '~/services/tracing';

//Define Global variables here and make them accessible to other components via provide / inject
const brandColors = {
  JOYA_BLUE: '#23bae5',
  JOYA_PURPLE: '#a271b1',
  JOYA_RED: '#f04941',
  JOYA_ORANGE: '#febb2a',
  JOYA_YELLOW: '#f2e03f',
  JOYA_GREEN: '#afd136'
} as BrandColors;

const TERMS_LINK_URL = 'https://www.marcopolo.me/terms/';
const POLICY_LINK_URL = 'https://www.marcopolo.me/privacy-policy/';

export default defineComponent({
  components: { Toast },
  provide() {
    return {
      [BrandColorsInjectionKey as symbol]: brandColors,
      termsLink: TERMS_LINK_URL,
      policyLink: POLICY_LINK_URL
    };
  },
  setup() {
    const toast = useToast();

    if (tracing.showEvents) {
      tracing.onEvent(event => {
        toast.add({
          severity: 'info',
          summary: event.event_name,
          detail: JSON.stringify(event.properties, null, 2),
          life: 4000,
          group: 'tracing'
        });
      });
    }

    if (tracing.showErrors) {
      tracing.onError(error => {
        toast.add({
          severity: 'error',
          summary: error.message,
          detail: error.stack,
          life: 4000,
          group: 'tracing'
        });
      });
    }

    return {
      toastBreakpoints: {
        '25rem': { width: 'auto', top: '20px', right: '0', left: '0', transform: 'none' }
      }
    };
  }
});
</script>

<style lang="scss">
/*
  Global styles
*/
@import 'App.scss';
</style>

// Type of environment
export enum EnvironmentType {
  Test = 'test',
  LocalDev = 'localdev',
  Dev = 'dev',
  Prod = 'prod'
}

// Settings defined in environment config
export interface EnvironmentSettings {
  type: EnvironmentType;
  commitId?: string;
  sentryUrl?: string;
  helpScoutBeaconId?: string;
  isVSUEnabled: boolean;
  mpApiBaseUrl: string;
}

/**
 * Provides information about the current environment in a consistent and typed way.  Instances initialize to the
 * default settings and can be set once with the real settings.  Setting it multiple times, or setting it after the
 * settings are read will generate errors.
 */
export class Environment {
  private _settings: EnvironmentSettings;

  // Track whether the settings have been read.
  private _wasSettingsRead = false;

  // Expose the environment type
  get type(): EnvironmentType {
    return this.settings.type;
  }

  // Get the instance settings
  get settings(): EnvironmentSettings {
    return this._settings;
  }

  constructor(settings: EnvironmentSettings) {
    this._settings = settings;
  }

  /**
   * Determine the environment type enum value from an environment mode string
   */
  static environmentTypeFromMode(mode: string): EnvironmentType | undefined {
    return Object.values(EnvironmentType).find(current => current.toString() === mode);
  }
}

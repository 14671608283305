import { EnvironmentType } from '~/environment';
import { RouteRecordRaw } from 'vue-router';
import { useEnsured as useEnvironmentEnsured } from './composables/use-environment';

const environment = useEnvironmentEnsured();

const featureRoutes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'index',
    component: () => import('./pages/Index.vue')
  },
  {
    path: '/sharecast/:shareToken',
    name: 'sharecast-link',
    component: () => import('./pages/Join.vue')
  },
  {
    path: '/sharecast/i/:inviteToken',
    name: 'sharecast-invite',
    component: () => import('./pages/Join.vue')
  },
  {
    path: '/sharecast/m/:messageToken',
    name: 'sharecast-message',
    component: () => import('./pages/Watch.vue')
  },
  {
    path: '/pro/m/:messageToken',
    name: 'pro-message',
    component: () => import('./pages/WatchPro.vue')
  },
  {
    path: '/give-plus/:partner',
    name: 'give-plus',
    component: () => import('./pages/GivePlus.vue')
  },
  {
    path: '/probeta',
    name: 'probeta',
    component: () => import('./pages/ProBeta.vue')
  },
  {
    path: '/account-preservation',
    name: 'account-preservation',
    component: () => import('./pages/AccountPreservation.vue')
  }
];

if (environment.settings.isVSUEnabled) {
  featureRoutes.push({
    path: '/seconds/:shareToken',
    name: 'seconds-album',
    component: () => import('./pages/Album.vue')
  });
}

if (environment.settings.type !== EnvironmentType.Prod) {
  featureRoutes.push({
    path: '/swagger',
    name: 'swagger',
    component: () => import('./pages/Swagger.vue')
  });
}

const commonRoutes: RouteRecordRaw[] = [
  {
    path: '/error',
    name: 'error',
    component: () => import('./pages/Error.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    props: true,
    component: () => import('./pages/404.vue')
  }
];

export default [...featureRoutes, ...commonRoutes];

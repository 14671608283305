import { AxiosInstance, AxiosResponse, AxiosRequestConfig } from 'axios';

import type { default as Ajv } from 'ajv';
import { Configuration } from './generated/configuration';
import { RequestArgs } from './generated/base';

/**
 * How severe the failure is, if it happened for a request.
 */
export enum RequestFailureSeverity {
  LOG = 'log', // default
  WARNING = 'warn',
  ERROR = 'error'
}

/**
 * Custom attributes can be added to request's config.
 * We use it to add more context to an error, in case it was unhandled and is request failure.
 */
export interface MPAxiosRequestConfig extends AxiosRequestConfig {
  // human-readable name for the request
  nickname: string;

  // severity of the failure
  failureSeverity: RequestFailureSeverity;
}

/**
 * Class for validating payloads against the swagger schema using ajv.
 */
class SchemaValidator {
  private ajv: Ajv;

  constructor(ajv: Ajv) {
    this.ajv = ajv;
  }

  /**
   * Create a SchemaValidator after dynamically importing dependencies.  This avoids loading those dependencies if
   * they're not used.
   */
  static async create(): Promise<SchemaValidator> {
    const swagger = await import('../apis/swagger.json');

    const ajvModule = await import('ajv');

    // Prepare ajv with custom options and our schemas
    const ajv = new ajvModule.default({
      // Log violations instead of throwing
      strict: 'log',
      // Skip strict validation of schemas since this is swagger documentation that includes fields beyond what's
      // normally in schema
      strictSchema: false
    });
    
    // Disabled loading of the Schema to comply with CSP. 
    //ajv.addSchema(swagger, 'swagger.json');

    return new SchemaValidator(ajv);
  }

  /**
   * Validate payload data against our schema, logging any validation issues.
   * @param typeName Name of the schema type for this data
   * @param data Payload data
   * @returns Whether the payload matches the schema.
   */
  validate(typeName: string, data: unknown): boolean {
    // Disabled validaiton to comply with CSP.
    //   const isValid = this.ajv.validate(
    //     {
    //       // Provide the reference to the type for this data
    //       $ref: `swagger.json#/components/schemas/${typeName}`
    //     },
    //     data
    //   );
    //   if (!isValid) {
    //     console.error(
    //       `Failed api schema vaidation for type ${typeName} : ${JSON.stringify(this.ajv.errors)}`
    //     );
    //   }
    //   return isValid;
    return true;
  }
}

let validator: SchemaValidator | undefined;

/**
 * Modified version of the original createRequestFunction from the opanapi-generator.
 *
 * Our version:
 * - takes an additional parameter that gives the name of the response type
 * - validates successful responses against the schema for that type (in dev and localdev)
 * - makes proper use of generics
 *
 * To simplify writing this logic we don't emit the function in generated code, as done with the original
 * createRequestFunction.  Instead, we change the imports in the generated code to a relative path to this file.
 */
export function createRequestFunction<TResponseData, TRequestData>(
  axiosArgs: RequestArgs,
  globalAxios: AxiosInstance,
  BASE_PATH: string,
  typeName: string,
  configuration?: Configuration
): (
  axios?: AxiosInstance,
  basePath?: string
) => Promise<AxiosResponse<TResponseData, TRequestData>> {
  // We use an empty base path, since we provide the base path to the axios instance, and don't want to override it
  // here
  return async (axios: AxiosInstance = globalAxios, basePath = '') => {
    const url = (configuration?.basePath || basePath) + axiosArgs.url;
    const axiosRequestArgs: Partial<MPAxiosRequestConfig> = {
      ...axiosArgs.options,
      url
    };
    const requestPromise = axios.request<
      TResponseData,
      AxiosResponse<TResponseData, TRequestData>,
      TRequestData
    >(axiosRequestArgs);

    // In dev and localdev environments validate all successful responses that return data
    if (['localdev', 'dev'].includes(import.meta.env.MODE) && typeName !== 'void') {
      const response = await requestPromise;
      if (response.status === 200) {
        try {
          const schemaValidator = validator || (await SchemaValidator.create());
          schemaValidator.validate(typeName, response.data);
        } catch (error) {
          console.error(`Failed to validate response for ${url}: ${error}`);
        }
      }
    }

    return requestPromise;
  };
}

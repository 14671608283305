import * as Vue from 'vue';

import { Router } from 'vue-router';
import sentry from '~/services/sentry';
import tracing from '~/services/tracing';

export default {
  install(app: Vue.App<Element>, options: { router: Router }) {
    sentry.install(app, options.router);

    tracing.install(app);
  }
};

import { Types, createInstance } from '@amplitude/analytics-browser';

import type { App } from 'vue';
import type { InjectionKey } from 'vue';

export { Identify, Types } from '@amplitude/analytics-browser';

export const _injectionKey = Symbol() as InjectionKey<Types.BrowserClient>;

export enum EventProperties {
  Error = 'Error',
  AppPlatform = 'AppPlatform'
}

const isProd = import.meta.env.MODE === 'prod';

export default {
  install(app: App<Element>) {
    const amplitudeAPIKey = import.meta.env.VITE_AMPLITUDE_API_KEY;

    if (!amplitudeAPIKey) {
      throw new Error('Missing VITE_AMPLITUDE_API_KEY');
    }

    const instance = createInstance();

    instance.init(amplitudeAPIKey, {
      defaultTracking: true,
      logLevel: isProd ? undefined : Types.LogLevel.Debug
    });

    app.provide(_injectionKey, instance);

    // When the user navigates away from the page, we want to resort to using the `sendBeacon` API
    self.addEventListener('pagehide', () => {
      instance.setTransport('beacon');
      // Sets https transport to use `sendBeacon` API
      instance.flush();
    });
  }
};
